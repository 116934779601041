import React from 'react';
import syllabusLink from '../../../.../../../../../../images/box-arrow-up-right.svg';
import syllabusLock from '../../../.../../../../../../images/syllabus-lock-fill.svg';
import { Link } from 'react-router-dom';
import { englishToBengaliNumberFormat, topicType } from '../../../../../../../utilities/utilities';
import { useState } from 'react';
import AssessmentModal from './AssessmentModal/AssessmentModal';
import { useEffect } from 'react';

const Topics = ({topic, topicSerial, LessonSerial, lessonName, unlock,lessons}) => {
    const [assignmentModalShow, setAssignmentModalShow] = useState(false);
    const [showUnlock, setShowUnlock] = useState(false);
    const [stop, setStop] = useState(true);
    const [syllabusContent, setSyllabusContent] = useState({});

    useEffect(() => {
        async function fetchMyAPI() {
            let response = await fetch(
                `${process.env.REACT_APP_BASE_URL_STUDENT}get-topic-assesment?topic_id=${topic?.topic_id}&user_id=${localStorage.getItem("user_id")}`,{
                    method:'GET',
                    headers:{
                        'content-type' : 'application/json',
                        'accept' : 'application/json',
                        'Authorization' : `Bearer ${localStorage.getItem("auth_token")}`,
                        'SecretKey' : `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
                    },
                }
            );
            response = await response.json();
            setSyllabusContent(response.data.topic);
        }
        fetchMyAPI();
    }, [topic?.topic_id]);


      if(topicSerial === 0 && LessonSerial >0 && stop){
        if(lessons[LessonSerial-1]?.topics[lessons[LessonSerial-1]?.topics?.length - 1]?.is_viewed === 1 && lessons[LessonSerial]?.topics[0]?.is_viewed === 0){
            setShowUnlock(true);
            setStop(false);
        }
      }

    return (
        <tr>
            <td className="topic-serial balooda-regular">
                {englishToBengaliNumberFormat(LessonSerial + 1)}.
                {englishToBengaliNumberFormat(topicSerial + 1)}
            </td>
            <td className='topic-category'><div className='d-flex align-items-center'>{ topicType(topic?.assesment_type) }</div></td>
            <td className='topic-serial'>:</td>
            <td className='topic-details'>{topic?.topic_name}</td>
            <td className='btn-topic-link text-end'>
                {
                    unlock  ?
                    <div className='d-flex align-items-center justify-content-end'>

                    {
                        syllabusContent?.assignment?.submission_exists ? 
                        <Link to='/view-submission' 
                          state={{ assignment_id: syllabusContent?.assignment?.id,
                                       total_attempt: syllabusContent?.assignment?.total_attempts,
                                       no_of_attempts: syllabusContent?.assignment?.no_of_attempts,
                                       subject: syllabusContent?.assignment.subject_name,
                                       total_questions: syllabusContent?.assignment?.total_questions,
                                       total_marks: syllabusContent?.assignment?.total_marks }}
                          className='btn-submission-syllabus text-center d-flex align-items-center me-3' style={{ fontSize:'12px', padding: '3.5px 15px', }}>
                          <span className=''>সাবমিশন দেখুন</span>
                      </Link>:" "
                    }
                    
                   

                    <p className='mb-0' onClick={() => setAssignmentModalShow(true)}><img src={syllabusLink} alt="" /></p>
                    <AssessmentModal show={assignmentModalShow} syllabus_content={syllabusContent}  onHide={() => setAssignmentModalShow(false)} lessonname={lessonName} topicid={topic?.topic_id} topicname={topic?.topic_name}/> 
                    </div>

                    :

                    (topic?.is_viewed === 1 || (topicSerial === 0 && LessonSerial === 0) || topic?.lock_unlock || showUnlock )?
                    <div className='d-flex align-items-center justify-content-end'>

                    {
                        syllabusContent?.assignment?.submission_exists ? 
                        <Link to='/view-submission' 
                          state={{ assignment_id: syllabusContent?.assignment?.id,
                                       total_attempt: syllabusContent?.assignment?.total_attempts,
                                       no_of_attempts: syllabusContent?.assignment?.no_of_attempts,
                                       subject: syllabusContent?.assignment.subject_name,
                                       total_questions: syllabusContent?.assignment?.total_questions,
                                       total_marks: syllabusContent?.assignment?.total_marks }}
                          className='btn-submission-syllabus text-center d-flex align-items-center me-3' style={{ fontSize:'12px', padding: '3.5px 15px', }}>
                          <span className=''>সাবমিশন দেখুন</span>
                      </Link>

                      :
                      
                      " "
                    }
                    
                   

                    <p className='mb-0' onClick={() => setAssignmentModalShow(true)}><img src={syllabusLink} alt="" /></p>
                        <AssessmentModal show={assignmentModalShow} syllabus_content={syllabusContent}  onHide={() => setAssignmentModalShow(false)} lessonname={lessonName} topicid={topic?.topic_id} topicname={topic?.topic_name}/> 
                    </div>

                    :
                    <p className='mb-0' ><img src={syllabusLock} alt="" /></p>   
                }
            </td>
            
        </tr>
    );
};

export default Topics;