import React from "react";
import { Link } from "react-router-dom";
import notFound from "../../../../../images/default_course_image.jpg";
import { englishToBengaliNumberFormat } from "../../../../../utilities/utilities";
import { encode as base64_encode } from "base-64";

const Card = ({ course, path_link }) => {
    const {
        course_name,
        course_current_price,
        course_initial_price,
        course_picture,
        id,
    } = course;
    let encoded = base64_encode(`${id}`);

    return (
        <div className="col col-lg-4  col-md-6 col-12">
            <Link to={`/skills/course-detail?course_id=${encoded}`} className="card-text-decoration">
                
                <div className="p-3 card_body card-hover-effect">
                    <div className="row">
                        <div className="col-lg-12 d-flex justify-content-center responsive-course-image">
                            {course_picture !== undefined &&
                            course_picture !== null ? (
                                <img
                                    src={`${path_link}${course_picture}`}
                                    className="book_border w-100"
                                    alt=""
                                    id="course-img"
                                />
                            ) : (
                                <img
                                    src={notFound}
                                    className="book_border w-100 default-img-size"
                                    alt=""
                                    id="course-img"
                                />
                            )}
                        </div>
                        <div className="col-lg-12">
                            <p className="course_book_name_size">{course_name}</p>
                            <p style={{ color: "#D0202F", fontWeight: "500" }}>
                                ডিজিটাল মার্কেটিং
                            </p>

                            <div className="card-divider"></div>

                            <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex flex-column flex-lg-row flex-md-row">
                                    {course_initial_price === "0" &&
                                    course_current_price === "0" ? (
                                        <p
                                            className="mb-0"
                                            style={{
                                                color: "#532d80",
                                                fontSize: "25px",
                                                fontWeight: "600",
                                            }}
                                        >
                                            ফ্রি কোর্স
                                        </p>
                                    ) : course_initial_price !== "0" &&
                                    course_current_price !== "0" &&
                                    course_initial_price ===
                                        course_current_price ? (
                                        <p
                                            className="text_color_blue balance_symbol"
                                            style={{ fontSize: "25px" }}
                                        >
                                            ৳
                                            <span>
                                                {englishToBengaliNumberFormat(
                                                    course_initial_price
                                                )}
                                            </span>
                                        </p>
                                    ) : (
                                        <>
                                            <p
                                                className="text_color_blue balance_symbol balooda-medium"
                                                style={{ fontSize: "25px" }}
                                            >
                                                ৳
                                                <span>
                                                    {englishToBengaliNumberFormat(
                                                        course_current_price
                                                    )}
                                                </span>
                                            </p>
                                            <del
                                                className="ms-lg-2 text_color_blue d-flex align-items-center balooda-medium"
                                                style={{
                                                    fontSize: "16px",
                                                    fontWeight: "500",
                                                }}
                                            >
                                                ৳
                                                {englishToBengaliNumberFormat(
                                                    course_initial_price
                                                )}
                                            </del>
                                        </>
                                    )}
                                </div>

                                {/* <div>
                                    <p
                                        className="px-3 rounded-3 text_color_org a_tag_decoration button_border"
                                        style={{
                                            backgroundColor: "#f27321",
                                            color: "#ffffff",
                                        }}
                                    >
                                        আরো দেখুন
                                    </p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default Card;
