import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import dashboard from '../../../images/dashboard.svg';
import liveStreaming from '../../../images/live-streaming.svg';
import calendar from '../../../images/calendar.svg';
import course from '../../../images/course.svg';
import profile from '../../../images/profile.png';
import eBook from "../../../images/e-book.png";
import Header from './Header/Header';
import './Dashboard.css'


const Dashboard = () => {
    return (
        <div className='student-background-color'>
            <Header></Header>
                <div className="vertical-menu">
                    <div data-simplebar className="h-100 p-0 pt-lg-3 pt-md-3 pt-2 sidebar">
                        <ul className="d-flex justify-content-center p-0" style={{ listStyle: 'none' }}>
                            <div className="sider-bar-items" >
                                
                                <li>
                                    <NavLink to='/dashboard' className="dashboard-nav">
                                        <img src={dashboard} alt=""
                                            className="d-flex justify-content-center sidebar-icon"/>
                                        <p className="pt-lg-2 pt-md-2 site-bar-text fw-bold text-center sidebar-name mb-0">ড্যাশবোর্ড</p>
                                    </NavLink>
                                </li>

                                <li className="pt-lg-3">
                                    <NavLink to='my-course' className="dashboard-nav">
                                        <img src={course} alt=""
                                            className="d-flex justify-content-center sidebar-icon"/>
                                        <p className="pt-lg-2 pt-md-2 site-bar-text fw-bold text-center sidebar-name mb-0">আমার কোর্স</p>
                                    </NavLink>
                                </li>

                                <li className="pt-lg-3">
                                    <NavLink to='live-class' className="dashboard-nav">
                                        <img src={liveStreaming} alt=""
                                            className="d-flex justify-content-center sidebar-icon"/>
                                        <p className="pt-lg-2 pt-md-2 site-bar-text fw-bold text-center sidebar-name mb-0">লাইভ ক্লাস</p>
                                    </NavLink>
                                </li>
                                <li className="pt-lg-3">
                                    <NavLink to='calendar' className="dashboard-nav">
                                        <img src={calendar} alt=""
                                            className="d-flex justify-content-center sidebar-icon"/>
                                        <p className="pt-lg-2 pt-md-2 site-bar-text fw-bold text-center sidebar-name mb-0">ক্যালেন্ডার</p>
                                    </NavLink>
                                </li>

                                <li className="pt-lg-3">
                                    <NavLink to='my-books' className="dashboard-nav">
                                        <img src={eBook} alt=""
                                            className="d-flex justify-content-center sidebar-icon"/>
                                        <p className="pt-lg-2 pt-md-2 site-bar-text fw-bold text-center sidebar-name mb-0"> ই-বুক</p>
                                    </NavLink>
                                </li>
                                {/* <li className="pt-lg-3">
                                    <NavLink to='myVideo' className="dashboard-nav">
                                        <img src={course} alt=""
                                            className="d-flex justify-content-center sidebar-icon"/>
                                        <p className="pt-lg-2 pt-md-2 site-bar-text fw-bold text-center sidebar-name mb-0"> ই-বুক</p>
                                    </NavLink>
                                </li> */}
                                <li className="pt-lg-3">
                                    <NavLink to='profile' className="dashboard-nav-profile">
                                            <img src={profile} alt=""
                                            className="d-flex justify-content-center profile-img"/>
                                        <p className="pt-lg-2 pt-md-2 site-bar-text fw-bold text-center">প্রোফাইল</p>
                                    </NavLink>
                                </li>
                                {/* <div className="sidebar-footer">
                                    <li className="dashboard-nav-profile">
                                        <NavLink to='profile' className="dashboard-nav-profile">
                                                <img src={profile} alt=""
                                                className="d-flex justify-content-center profile-img"/>
                                            <p className="pt-lg-2 pt-md-2 site-bar-text fw-bold text-center">প্রোফাইল</p>
                                        </NavLink>
                                    </li>
                                </div> */}
                            </div>
                        </ul>
                    </div>
                </div>
            <Outlet />

            
                                
        </div>
    );
};

export default Dashboard;