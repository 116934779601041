import React from 'react';
import firstPositionIcon from '../../../../../../images/leader-board-first.svg';
import secondPositionIcon from '../../../../../../images/leader-board-second.svg';
import thirdPositionIcon from '../../../../../../images/leader-board-third.svg';
import noPerson from '../../../../../../images/blank.svg';

const LeaderBoardStudent = ({leaderBoardStudent,index,count,studentImagePath}) => {
    return (
        <>
            <tr  className={leaderBoardStudent?.user_id === parseInt(localStorage.getItem("user_id")) ? 'leader-board-own-position leader-board-corner' : 'leader-board-corner' }>
            
                <td className="position-icon py-3">
                    {
                        index === 0 ? <img src={firstPositionIcon} alt="" /> : index === 1 ? <img src={secondPositionIcon} alt="" /> : index === 2 ? <img src={thirdPositionIcon} alt="" /> : ''
                    }
                    
                </td>
                <td className="rank py-3 text-center">
                    {index+1}
                </td>
                <td className="leader-board-student-name py-3">
                    <div className="d-flex align-items-center">
                    {
                        leaderBoardStudent?.profile_picture === undefined || leaderBoardStudent?.profile_picture === null ?
                        <img src={noPerson} alt="" className='leader-board-student-img'/>
                        :
                        <img src={`${studentImagePath}${leaderBoardStudent?.profile_picture}`} alt="" className='leader-board-student-img'/>
                    }
                        <p className="mb-0 ps-2">{leaderBoardStudent?.user_name}</p>
                        
                        {leaderBoardStudent?.user_id === parseInt(localStorage.getItem("user_id")) ? <p className="mb-0 ms-2 own-indicate">You</p> : '' }
                    </div>
                </td>
                <td className="text-center leader-board-marks-section py-3">
                    <div className='d-flex justify-content-center'>
                        <p className="mb-0 leader-board-marks">{Math.round(leaderBoardStudent?.marks) === 0  ? 0 : leaderBoardStudent?.marks} %</p>
                    </div>
                </td>
            </tr>

            {
                index + 1 !== count && <tr><td className="p-0" colSpan={5}><div className='divider-border'></div></td></tr>
            }
            
            
        </>
    );
};

export default LeaderBoardStudent;