import React, { useState } from "react";
import AdmissionType from "../../Pages/AdmissionType/AdmissionType";
import BalanceSection from "../../Pages/BalanceSection/BalanceSection";
import PaymentMethod from "../../Pages/PaymentMethod/PaymentMethod";
import backIcon from "../../../../images/back-icon.svg";

const OffCanvas = ({payment, affiliate_id}) => {

    const [active, setActive] = useState("admission-type");
    const [data, setData] = useState({});
    const handleActiveBalanceSection = () => {
        setActive("balance-section");
    };
    const handleActivePaymentMethod = () => {
        setActive("payment-method");
    };

    const handelBack = () => {
        if (active === "balance-section") setActive("admission-type");
        else if (active === "payment-method") setActive("balance-section");
        else document.getElementById("close").click();

    };

    return (
        <div className="offcanvas offcanvas-end payment-select" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
            <div className="offcanvas-header" style={{ padding: "2rem" }}>
                <button id="offcanvasRightLabel" className="payment-back" onClick={handelBack}>
                    <img src={backIcon} alt="" />
                    <span className="ps-2">আগের পেজে ফিরে যাও</span>
                </button>
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" id="close" style={{ display: "none" }}></button>
            </div>
            <div className="offcanvas-body payment-offcanvas" style={{ padding: "1rem 2rem 2rem 2rem" }}>
                {active === "admission-type" && (<AdmissionType handleActiveBalanceSection={handleActiveBalanceSection}/>)}
                {active === "balance-section" && ( <BalanceSection payment={payment} setData={setData} handleActivePaymentMethod={handleActivePaymentMethod} affiliate_id={affiliate_id} />)}
                {active === "payment-method" && <PaymentMethod data={data}/>}
            </div>
        </div>
    );
};

export default OffCanvas;
