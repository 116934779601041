import React, { useState } from 'react';
import parse from 'html-react-parser';

const MultipleChoice = ({question, handelRadioButton, endSlice, index}) => {
    const handelRadio =(event) =>{
        handelRadioButton(event.target.value, question?.id);
    }
    const [selectedMCAValue, setSelectedMCAValue] = useState('');
    const handleChangeMCA = (event) => {
        setSelectedMCAValue(event.target.value);
    }
    return (
        <>
            <div className='d-flex justify-content-between mb-4 mt-5'>
                <p className='mb-0 ' style={{ fontWeight:'400' }}>Question No #{index ? index : endSlice}</p>
                <p className='mb-0' style={{ fontWeight:'400' }}>Question Type: 
                {/* <span className='single-question-type'>
                    {question?.question_type_id === 1 ? 'Multiple Choice Question': question?.question_type_id === 2 ? 'Multiple Answer': 'TrueFalse' }
                    </span> */}
                    <span className='single-question-type'>
                        {question?.question_type_id === 1 ? 'Multiple Choice Question': 
                        question?.question_type_id === 2 ? 'Multiple Answer': 
                        question?.question_type_id === 3 ? 'TrueFalse': 
                        question?.question_type_id === 4 ? 'Broad Question': ''}
                    </span>
                </p>
            </div>
            <div className='single-question text-center unselectable'>{parse(question?.title)}</div>
            <div className='d-flex justify-content-center mb-5'>
                <div className='option-width' onChange={handelRadio}>
                    {
                        question?.options?.map(option =>
                            <div key={option?.id} className="form-check option d-flex justify-content-between align-items-center">
                                <label className="form-check-label unselectable preference question-option-shadow" htmlFor={`multiple_choice_${option?.id}`} style={{ border: selectedMCAValue === `${option?.id}` ? '1px solid #532d80' : '1px solid #fff', cursor:'pointer' }}>{option?.answer_details}</label>
                                <input className="form-check-input p-1 me-3" type="radio" name={`multiple_choice_${question?.id}`} id={`multiple_choice_${option?.id}`} value={`${option?.id}`} style={{ border:'2px solid #DEDEDE' }} onChange={handleChangeMCA}/>
                            </div>
                        )
                    }
                </div>
            </div> 
        </>
    );
};

export default MultipleChoice;