import React from 'react';
import parse from 'html-react-parser';
import { useState } from "react";
import AnswerImage from "./AnswerImage/AnswerImage";
import './FileSubmussion.css';

const FileSubmission = ({ question, handelFileSubmission, endSlice, index }) => {
    const [images, setImages] = useState([]);
    const [submitAnswer, setSubmitAnswer] = useState(true);
    /* const [allPath, setAllPath] = useState(""); */
    const handleMultipleImages = (evnt) => {
        const selectedFIles = [];
        let allPath = '';
        const targetFiles = evnt.target.files;
        const targetFilesObject = [...targetFiles];
        targetFilesObject.map((file, index) => {
            let data = new FormData();
            data.append("assignment_file", file);

            fetch(
                `${process.env.REACT_APP_BASE_URL_STUDENT}file-upload-from-app`,
                {
                    method: "POST",
                    header: {
                        "content-type": "application/json",
                    },
                    body: data,
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    if (data.message_type === "success") {
                         allPath = allPath + data.data.photo + ",";
                    } else {
                    }
                });
            return selectedFIles.push(URL.createObjectURL(file));
        });

        setSubmitAnswer(false);

        setTimeout(myGreeting, 2000);
        function myGreeting() {
            handelFileSubmission(allPath.replace(/,\s*$/, ""), question?.id);
            setSubmitAnswer(true);
        }
        setImages(selectedFIles);
    };

    return (
        <>
            <div className='d-flex justify-content-between mb-4 mt-5'>
                <p className='mb-0' style={{ fontWeight:'400' }}>Question No #{index ? index : endSlice}</p>
                <p className='mb-0' style={{ fontWeight:'400' }}>Question Type: 
                    <span className='single-question-type'>
                        {question?.question_type_id === 1 ? 'Multiple Choice Question': 
                        question?.question_type_id === 2 ? 'Multiple Answer': 
                        question?.question_type_id === 3 ? 'TrueFalse': 
                        question?.question_type_id === 4 ? 'Broad Question': 'File Submission'}
                    </span>
                </p>
            </div>
        <div className='single-question text-center unselectable'>{parse(question?.title)}</div>
        
            
            {/* <div className="d-flex justify-content-center form-group my-3 mx-3">
                <input type="file" onChange={handleMultipleImages} multiple />
            </div> */}
            <label htmlFor="images" className="drop-container mb-3" onChange={handleMultipleImages} >
                <span className="drop-title">Click Here to Select All Answers file and Submit Your Answers</span>
                <input type="file" id="images" accept="image/*" multiple required style={{ display: 'none' }}/>
            </label>
            
            {
               images.length > 0 && 
               <h2>Answer: </h2>
            }
            <div className='container'>
                {
                    submitAnswer? <AnswerImage images={images} /> :  <p className='text-danger text-center fs-3'>Please Wait...</p> 
                }
                
            </div>
        </>
    );
};

export default FileSubmission;
