import React, { useEffect, useState } from 'react';
import { englishToBengaliNumberFormat } from '../../../../utilities/utilities';
import StudentPageTitle from '../../shared/StudentPageTitle/StudentPageTitle';
import ClassCard from './ClassCard/ClassCard';

const LiveClass = () => {
    const [liveCLasses, setLiveClasses] = useState([]);

    useEffect(() => {
        async function fetchLiveClassInfo() {
            let response = await fetch(
                `${process.env.REACT_APP_BASE_URL_STUDENT}live-classes?user_id=${localStorage.getItem("user_id")}`,{
                    method:'GET',
                    headers:{
                        'content-type' : 'application/json',
                        'accept' : 'application/json',
                        'Authorization' : `Bearer ${localStorage.getItem("auth_token")}`,
                        'SecretKey' : `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
                    },
                })
            response = await response.json();
            setLiveClasses(response);
        }

        fetchLiveClassInfo();
    }, []);


    return (
        <div className="main-content">
        <StudentPageTitle title="Live Class"></StudentPageTitle>
            <div className="page-content p-0">
                <div className="container-fluid">
                <div className='my-course-section'>
                        <p className='my-course-title mb-1'>লাইভ ক্লাস</p>
                        <p className='mb-4 subscription-duration'>তোমাকে অ্যাসাইন করা কোর্সে যেসকল লাইভ ক্লাস আছে তার তালিকা</p>
                        <div className='d-flex mb-3'>
                            <h4>আজকের ক্লাস</h4> 
                            {
                                liveCLasses?.today_live_classes?.length !== 0 && 
                                <span className='subscription-duration ms-3 text-bottom pt-1 balooda-regular' style={{ fontSize:'16px' }}>{englishToBengaliNumberFormat(liveCLasses?.today_live_classes?.length)}টি ক্লাস</span>
                            }
                        </div>
                        <div className="row g-3">
                        {liveCLasses?.today_live_classes?.length !== 0 ? (
                            liveCLasses?.today_live_classes?.map(
                                (liveCLass) => (
                                    <ClassCard
                                        key={liveCLass.live_class_id}
                                        liveCLass={liveCLass}
                                        toDayClass = {true}
                                    ></ClassCard>
                                )
                            )
                        ) : (
                            <p
                                className="d-flex justify-content-center mb-0 w-100"
                                style={{ fontSize: "22px", fontWeight: "600" }}
                            >
                                আজ তোমার কোনো ক্লাস নেই।
                            </p>
                        )}
                        </div>

                        {liveCLasses?.upcoming_live_classes?.length !== 0 && (
                            <>
                                <div className='d-flex mb-3 mt-5'>
                                    <h4>আগামী কিছু দিনের ক্লাস</h4> 
                                    <span className='subscription-duration ms-3 text-bottom pt-1 balooda-regular' style={{ fontSize:'16px' }}>{englishToBengaliNumberFormat(liveCLasses?.upcoming_live_classes?.length)}টি ক্লাস</span>
                                </div>
                                <div className="row g-3">{
                                    liveCLasses?.upcoming_live_classes?.map(
                                        (liveCLass) => (
                                            <ClassCard
                                                key={liveCLass.live_class_id}
                                                liveCLass={liveCLass}
                                                toDayClass = {false}
                                            ></ClassCard>
                                        )
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LiveClass;