import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import assignmentComplete from '../../../../../../images/complete_page.svg';
import './AssignmentSubmitDone.css';

const AssignmentSubmitDone = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const assignment_name = location?.state?.assignment_name;
    const course_id = location?.state?.course_id;
    const batch_id = location?.state?.batch_id;
    const handelGoToDashboard =()=>{
        //navigate("/dashboard")
        navigate("/my-course/lms", {
            state: {
                course_id: course_id,
                batch_id: batch_id,
            },
        });
    }
    return (
        <div>
            <div className='d-flex justify-content-between align-items-center p-4'>
                <div className='btn-assignment-submit-and-close'>
                    <button type="button" className="btn-close" aria-label="Close" onClick={handelGoToDashboard}></button>
                </div>
                <p className='text-center mb-0 show-assignment-name'>{assignment_name}</p>
                <div></div>
            </div>
            <div className='total-assignment-divider'></div>

            <div className='container mt-3'>


            <div className='d-flex justify-content-center align-items-center' style={{ height:'80vh' }}>

                <div>
                    <div className='d-flex justify-content-center'>
                        <img src={assignmentComplete} className='img-fluid' alt="" />
                    </div>
                    <p className='complete-title text-center'>Completed Successfully!</p>
                    <p className='get-result-notice text-center'>We have received your submission. Mentor will evaluate the paper & publish the <br/> result within a very shotrt time.</p>
                    <div className='d-flex justify-content-center'>
                        <Link to='/my-course/lms' state={{ 
                            course_id: course_id,
                            batch_id: batch_id,
                            //class_content: 'assignment'
                         }} className='btn-submitAssignment-to-dashboard px-4 py-2'>Go to dashboard</Link>
                    </div>
                </div>
            </div>
                

                
               
                    




      

                        


                


            </div>
        </div>
    );
};

export default AssignmentSubmitDone;