import React from "react";
import { Link } from "react-router-dom";
import liveType from "../../../../../images/live type.svg";
import recordType from "../../../../../images/record type.svg";
import people from "../../../../../images/people-fill.svg";
import defaultImage from "../../../../../images/default_course_image.jpg";
import "./CourseCard.css";

const CourseCard = ({
    purchasedCourse,
    teacher_img_pathLink,
    course_img_pathLink,
}) => {
    /* let total_teachers = purchasedCourse?.course_teachers.length;
    let remaining_teacher = total_teachers - 3; */

    return (
        <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12" >
        <Link to="/my-course/lms"
                state={{
                    course_name: purchasedCourse?.course_name,
                    course_id: purchasedCourse?.course_id,
                    course_batch: purchasedCourse?.batch_name,
                    batch_id: purchasedCourse?.batch_id,
                    course_type: purchasedCourse?.course_mode,
                    course_total_student: purchasedCourse?.total_students,
                    course_start_date: purchasedCourse?.course_start_date,
                    course_end_date: purchasedCourse?.course_end_date,
                }}
                style={{ textDecoration:'none' }}
                
            >
                {/* বিস্তারিত দেখুন */}
            
            <div className="p-3 card-body my_course_card_body custom-card-shadow">
                <div className="row">
                    <div className="col-lg-12 responsive-course-image">
                        {purchasedCourse?.course_picture !== undefined &&
                        purchasedCourse?.course_picture !== null ? (
                            <img
                                src={`${course_img_pathLink}${purchasedCourse?.course_picture}`}
                                className="w-100"
                                alt=""
                                style={{ borderRadius: "15px" }}
                            />
                        ) : (
                            <img
                                src={`${defaultImage}`}
                                className="w-100"
                                alt=""
                                style={{ borderRadius: "15px" }}
                            />
                        )}
                    </div>

                    <div className="col-lg-12">
                        <div style={{ height: "140px" }}>
                            <div className="course_name_area mb-3">
                                <div className="mt-3 d-flex align-items-center">
                                    {purchasedCourse?.course_mode ===
                                    "Live course" ? (
                                        <img
                                            src={liveType}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    ) : purchasedCourse?.course_mode ===
                                      "Recorded course" ? (
                                        <img
                                            src={recordType}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    ) : (
                                        ""
                                    )}{" "}
                                    <p className="purchased_course_name mb-0 ms-2 text-dark">
                                        {purchasedCourse.course_name}
                                    </p>
                                </div>
                            </div>

                            <div className="d-flex align-items-center mb-3">
                                <img
                                    src={people}
                                    className="img-fluid"
                                    alt=""
                                />
                                <p className="course-batch-name mb-0 ms-2 text-dark">
                                    {purchasedCourse.batch_name}
                                </p>
                                {/* <div className='course-card-student-pointer ms-4'></div>
                                <p className='course-total-student mb-0 ms-2'> <span className='balooda-regular'>{englishToBengaliNumberFormat(purchasedCourse.total_students)}</span> জন ছাত্র/ছাত্রী</p> */}
                            </div>
                        </div>

                        {/* <div className='d-flex justify-content-between align-items-center mb-2'>
                            <p className=' mb-0 progress-bar-title'>কোর্স অগ্রগতি</p>
                            <p className=' mb-0 progress-bar-title'><span className='balooda-regular'>১৬/২০</span> টি ভিডিও দেখেছেন</p>
                        </div>
                        <div className="progress mt-1 mb-3" style={{borderRadius:'15px', height: '0.7rem' }}>
                            <div className="progress-bar" role="progressbar" style={{ width: '65%', borderRadius:'15px', backgroundColor:'#AC6BEC' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                        </div> */}

                        <p
                            to="/my-course/lms"
                            state={{
                                course_name: purchasedCourse?.course_name,
                                course_id: purchasedCourse?.course_id,
                                course_batch: purchasedCourse?.batch_name,
                                batch_id: purchasedCourse?.batch_id,
                                course_type: purchasedCourse?.course_mode,
                                course_total_student: purchasedCourse?.total_students,
                                course_start_date: purchasedCourse?.course_start_date,
                                course_end_date: purchasedCourse?.course_end_date,
                            }}
                            className="btn-course text-center mb-0"
                        >
                            বিস্তারিত দেখুন
                        </p>
                    </div>
                </div>
            </div>
            </Link>
        </div>
        /*  <div className="col-lg-3 col-md-6 col-sm-12 col-12">
            <div className='p-3 card-body my_course_card_body'>
                <div className="row">
                    <div className="col-lg-12">
                        <img src={`${course_img_pathLink}${purchasedCourse?.course_picture}`} className='w-100' alt=""  style={{ borderRadius:'15px' }}/>
                    </div>

                    <div className='col-lg-12'>
                        <div className='course_name_area'>
                            <div className='mt-3 d-flex justify-content-between align-items-center'>
                                <p className='purchased_course_name mb-0'>{purchasedCourse.course_name}</p>
                                <div className='purchased_batch_background'>
                                    <p className='purchased_batch_name text-center px-lg-3 py-lg-2 px-md-2 py-md-1 px-sm-1 py-sm-1 px-1 py-1 mb-0'>Batch: {purchasedCourse.batch_name}</p>
                                </div>
                            </div>
                        </div>


                        <div className='course_name_area'>
                            <div className='d-flex align-items-center'>
                                <div className="avatar-group ">

                                    {
                                        purchasedCourse?.course_teachers?.slice(0,3).map((course_teacher,index) => 
                                            <div className= { index == 0 ? "avatar" : "avatar avatar-animated"} key={course_teacher.id}>
                                                <img src={`${teacher_img_pathLink}${course_teacher?.teacher_profile_picture}`}/>
                                            </div>
                                        )
                                    }
                                    <div className="avatar hidden-avatars" style={remaining_teacher > 0? { display: "block" } : { display: "none" }}>
                                        <div className='d-flex justify-content-center align-items-center h-100'>
                                        +{remaining_teacher}
                                        </div>
                                    </div>
                                </div>
                                <p className='mb-0 ms-2 purchased_course_teacher_name'>{purchasedCourse?.course_teachers[0]?.teacher_name} {total_teachers > 1 ? 'এবং অন্নান্য' : ''} </p>
                            </div>
                        </div>

                        <div className='d-flex justify-content-between align-items-center'>
                            <p className=' mb-0 progress-bar-title'>কোর্স অগ্রগতি</p>
                            <p className=' mb-0 progress-bar-title'>১৬/২০ টি ভিডিও দেখেছেন</p>
                        </div>
                        <div className="progress mt-1 mb-3" style={{borderRadius:'15px' }}>
                            <div className="progress-bar" role="progressbar" style={{ width: '65%', borderRadius:'15px', backgroundColor:'#AC6BEC' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>


                    </div>
                </div>
            </div>               
        </div>    */
    );
};

export default CourseCard;
