import React, { useEffect, useState, lazy, Suspense } from "react";
import Banner from "./Banner/Banner";
import Courses from "./Courses/Courses";
/* import Books from "./Books/Books";
import Information from "./Information/Information";
import Think from "./Think/Think";
import Working from "./Working/Working";
import InformationMobileView from "./InformationMobileView.js/Information";
import BookMobileView from "./BooksMobileView/BooksMobileView";
import MassMedia from "./MassMedia/MassMedia"; */
import PageTitle from "../../shared/PageTitle/PageTitle";
import Loader from "../../shared/Loader/Loader";
import MessengerCustomerChat from "react-messenger-customer-chat";
import { Link } from "react-router-dom";
/* import Media from "../../../../components/student/Dashboard/Media/Media"; */
const CoursesMobileView = lazy(() => import('./CoursesMobileView/CoursesMobileView'));
const Books = lazy(() => import('./Books/Books'));
const BookMobileView = lazy(() => import('./BooksMobileView/BooksMobileView'));
const Information = lazy(() => import('./Information/Information'));
const Think = lazy(() => import('./Think/Think'));
const Working = lazy(() => import('./Working/Working'));
const InformationMobileView = lazy(() => import('./InformationMobileView.js/Information'));
const MassMedia = lazy(() => import('./MassMedia/MassMedia'));




const Home = () => {
    const [homePageInfo, setHomePageInfo] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(()=>{
        async function fetchHomePageInfo(){
            let response = await fetch(`${process.env.REACT_APP_BASE_URL}api/home-page-details`);
            response = await response.json();
            setHomePageInfo(response.data);
            setIsLoading(false)
        }

        fetchHomePageInfo()
    },[])

    if(isLoading){
        return (
            <>
                <Loader/>
            </>
        );
    }

    let newCount = homePageInfo?.news.length;


    return (
        <>
            <PageTitle title="Home"></PageTitle>
           {/*  <Header navlinks={homePageInfo.course_categories}/> */}
           {/* <div className="my-4" style={{ backgroundColor:"#C9F3D2" }}>
            <div className="container">
            <div className="row">

            <div className="col-lg-6 px-0">
                <img src="" alt="" />
            </div>
            <div className="col-lg-6 px-0">
                <div className="d-flex justify-content-center">
                    

                    <div class="trial-btn me-4" style={{ border:'1px solid #f27321', borderRadius:'4px' }}>
<a class="book_trial nav-item">
<button class="btn book_trial_btn color-fff" data-event-prop="top_right_nav" style={{ backgroundColor:'#fff' }}>
<span class="btn-text">

<span className="ms-1 text-dark"> Join Schedule Class</span>
</span>
</button>
</a>
</div>

                    <div class="trial-btn">
<a class="book_trial nav-item">
<button class="btn book_trial_btn color-fff" data-event-prop="top_right_nav" style={{ backgroundColor:'#f27321' }}>
<span class="btn-text">
<svg width="17" height="18" viewBox="0 0 17 18" fill="none">
<path d="M1.28571 16.7143V13.8214H4.17857V16.7143H1.28571ZM4.82143 16.7143V13.8214H8.03571V16.7143H4.82143ZM1.28571 13.1786V9.96428H4.17857V13.1786H1.28571ZM4.82143 13.1786V9.96428H8.03571V13.1786H4.82143ZM1.28571 9.32143V6.42857H4.17857V9.32143H1.28571ZM8.67857 16.7143V13.8214H11.8929V16.7143H8.67857ZM4.82143 9.32143V6.42857H8.03571V9.32143H4.82143ZM12.5357 16.7143V13.8214H15.4286V16.7143H12.5357ZM8.67857 13.1786V9.96428H11.8929V13.1786H8.67857ZM5.14286 4.5C5.14286 4.67076 4.99219 4.82143 4.82143 4.82143H4.17857C4.00781 4.82143 3.85714 4.67076 3.85714 4.5V1.60714C3.85714 1.43638 4.00781 1.28571 4.17857 1.28571H4.82143C4.99219 1.28571 5.14286 1.43638 5.14286 1.60714V4.5ZM12.5357 13.1786V9.96428H15.4286V13.1786H12.5357ZM8.67857 9.32143V6.42857H11.8929V9.32143H8.67857ZM12.5357 9.32143V6.42857H15.4286V9.32143H12.5357ZM12.8571 4.5C12.8571 4.67076 12.7065 4.82143 12.5357 4.82143H11.8929C11.7221 4.82143 11.5714 4.67076 11.5714 4.5V1.60714C11.5714 1.43638 11.7221 1.28571 11.8929 1.28571H12.5357C12.7065 1.28571 12.8571 1.43638 12.8571 1.60714V4.5ZM16.7143 3.85714C16.7143 3.15402 16.1317 2.57143 15.4286 2.57143H14.1429V1.60714C14.1429 0.723215 13.4196 0 12.5357 0H11.8929C11.0089 0 10.2857 0.723215 10.2857 1.60714V2.57143H6.42857V1.60714C6.42857 0.723215 5.70536 0 4.82143 0H4.17857C3.29464 0 2.57143 0.723215 2.57143 1.60714V2.57143H1.28571C0.582589 2.57143 0 3.15402 0 3.85714V16.7143C0 17.4174 0.582589 18 1.28571 18H15.4286C16.1317 18 16.7143 17.4174 16.7143 16.7143V3.85714Z" fill="#fff"></path>
</svg>
<span className="ms-1 text-white"> Book a Schedule Class</span>
</span>
</button>
</a>
</div>


                </div>
            </div>

            </div>
            
           </div>
           </div> */}
            <Banner settings={homePageInfo}/>
            <Courses categories = {homePageInfo?.course_categories} courses = {homePageInfo.courses} path_link={homePageInfo.course_image_path}/>
            <Suspense fallback={null}>
               <CoursesMobileView categories = {homePageInfo?.course_categories} courses = {homePageInfo.courses} path_link={homePageInfo.course_image_path}/>
            </Suspense>
            <Suspense fallback={null}>
               <Books categories = {homePageInfo?.ebook_categories} e_books = {homePageInfo.e_books} path_link={homePageInfo.ebook_image_path}/>
            </Suspense>
            <Suspense fallback={null}>
               <BookMobileView categories = {homePageInfo?.ebook_categories} e_books = {homePageInfo.e_books} path_link={homePageInfo.ebook_image_path} />
            </Suspense>
            <Suspense fallback={null}>
               <Information />
            </Suspense>
            <Suspense fallback={null}>
               <InformationMobileView />
            </Suspense>
            <Suspense fallback={null}>
                <Think/>
            </Suspense>
            <Suspense fallback={null}>
                <Working newCount = {newCount}/>
            </Suspense>
            <Suspense fallback={null}>
                <MassMedia news= {homePageInfo?.news} path_link={homePageInfo.news_image_path}/>
            </Suspense>

            <MessengerCustomerChat themeColor="#532D80" pageId="101066028601535" appId="1272114613611586"/>
            {/* <Message /> */}
            {/* <Footer /> */}
        </>
    );
};

export default Home;
