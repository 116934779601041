import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./Registration.css";
import registrationLogo from "../../../../images/reg_login_logo.png";
import regBackground from '../../../../images/bg.png';
import logoWatermark from '../../../../images/m_watermark.png';
import PageTitle from "../../shared/PageTitle/PageTitle";
import { englishToBengaliNumberFormat } from "../../../../utilities/utilities";

const Registration = () => {
    const [btnLoading, setBtnLoading] = useState(false);
    let navigate = useNavigate();

    const background_image={
        backgroundImage: 
        `url(${regBackground})`,
        height:'100vh',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    };


    const handelRegistration = (event) => {
        event.preventDefault();
        setBtnLoading(true);

        const user_information = {
            mobile_or_email: event.target.mobile_or_email.value,
        };

        fetch(
            `${process.env.REACT_APP_BASE_URL_STUDENT}registration`,
            {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify(user_information),
            }
        )
            .then((res) => res.json())
            .then((data) => {
                if (data.message_type === "success") {
                    navigate("/otp", {
                        state: {
                            mobile_or_email: event.target.mobile_or_email.value,
                            user_id: data.data.user_id,
                            message: data.message,
                        },
                        
                    });
                    setBtnLoading(false);
                } else {
                    toast.error(data.message);
                    setBtnLoading(false);
                }
            });
    };

    return (
        <section style={background_image}>
            <PageTitle title="Registration"></PageTitle>

            <div className="h-100 d-flex align-items-center position-relative register-container">
                <div className="w-100">
                    <Link to="/" className="logo d-flex justify-content-center">
                        <img src={registrationLogo} alt="" />
                    </Link>

                    <div className="input-container position-relative">
                        <div className="position-absolute top-50 start-50 translate-middle">
                            <img
                                src={logoWatermark}
                                alt=""
                                className="img-fluid"
                            />
                        </div>
                        <form onSubmit={handelRegistration}>
                            <p
                                className="text-center text-white"
                                style={{ fontSize: "32px", fontWeight: "500" }}
                            >
                                রেজিস্ট্রেশন করুন
                            </p>
                            <div className="input-section">
                                <input
                                    type="text"
                                    id="mobile_or_email"
                                    name="mobile_or_email"
                                    placeholder="Email / Phone number"
                                    className="input-area mobile_or_email"
                                    autoComplete="off"
                                />
                            </div>

                            <div className="d-flex justify-content-center position-relative">
                                <button
                                    className="btn_reg"
                                    disabled={btnLoading ? true : false}
                                >
                                    {btnLoading ? (
                                        <>
                                            <i className="fa fa-spinner fa-spin"></i>{" "}
                                            Next
                                        </>
                                    ) : (
                                        "Next"
                                    )}
                                </button>
                            </div>

                            <div className="d-flex justify-content-center position-relative mt-3">
                                <Link
                                    to="/login"
                                    className="text-decoration-none"
                                >
                                    <p className="text-white mb-0 fs-5">
                                        Back for LogIn Account
                                    </p>
                                </Link>
                            </div>
                            {/*  <Link
                                to="/login"
                                className="text-decoration-none mt-2"
                            >
                                <p className="text-white mb-0 fs-5">
                                    Back to log In
                                </p>
                            </Link> */}
                        </form>
                    </div>
                    <p className="position-absolute w-100 d-flex justify-content-center bottom-text">
                        কপিরাইট {englishToBengaliNumberFormat(new Date().getFullYear())} © মজারু এডুকেশন টেকনোলজিস লিমিটেড.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default Registration;
