import React from "react";
import './Blog.css';
import BlogPath from '../../../../../../images/blog_path.png';
import defaultBlogImage from '../../../../../../images/default-blog-thumb.png';
import { Link } from "react-router-dom";

const Blog = ({blog,blog_image_path}) => {
    /* const [isLoading, setISLoading] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            setISLoading(false)
        }, 2000);
      }, []); */

        const today = new Date(blog?.created_at);
        const month = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
        const date = today.getDate() + ' ' + month[today.getMonth()] + ', ' + today.getFullYear();
    return (
            <div className="col-lg-6 px-lg-4 px-md-4 mb-5">
            <Link to="/blog-details" state={{ 
                blog_title: blog?.blog_title,
                blog_details: blog?.blog_long_desc,
                img_path: blog_image_path,
                blog_img: blog?.blog_image
             }} style={{ textDecoration:'none', color:'#000000' }}>

                {(blog?.blog_image !== undefined && blog?.blog_image !== null) ? 
                    <img src={`${blog_image_path}${blog?.blog_image}`} alt="" className="img-fluid" />
                    : 
                    <img src={defaultBlogImage} alt="" className="img-fluid"/>
                }
             
                <p className="blog-title">{blog?.blog_title}</p>
                <div className="position-relative">
                    <img src={BlogPath} alt=""  className="position-absolute" style={{ height: '40px', width: '142px' }}/>
                    <div id="absolute">
                        <div className="centerd pt-2 ps-3">
                        <p className="blog-date">{date}</p> 
                        </div>
                    </div>
                </div>
                </Link>
            </div>
            );
        };
        
    export default Blog;
                
                
                
