import React from 'react';
import './Loader.css';
import loderIcon from '../../../../images/dashboard-logo.png';
/* import loderIcon from '../../../../../../../public/images/dashboard-logo.png';

const Loader = () => {
    return (
        <div>
            <div
                className=""
                style={{
                    height: "115vh",
                    zIndex: "2000",
                    backgroundColor: "#9c81bd",
                }}
            >
                <div className="d-flex justify-content-center align-items-center h-100">
                    <div>
                        <div className="zoom-in-zoom-out">
                            <img src={loderIcon} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Loader; */
const Loader = () => (
  <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    //backgroundColor: 'rgba(255, 255, 255, 0.5)',
    backgroundColor: '#9c81bd',
    
    zIndex:'2000'

  }}>
    {/* <div style={{
      border: '5px solid #fff',
      borderTop: '5px solid #3498db',
      borderRadius: '50%',
      width: '50px',
      height: '50px',
      animation: 'spin 1s linear infinite'
    }} /> */}
    <div>
        <img src={loderIcon} alt="Loading" />
    </div>
  </div>
);

export default Loader;