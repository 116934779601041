import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
/* import Student from "./components/student/Student"; */
import "./components/student/Student.css";
import 'react-loading-skeleton/dist/skeleton.css'
import Calendar from "./components/student/Dashboard/Calendar/Calendar";
import Dashboard from "./components/student/Dashboard/Dashboard";
import LatestCourses from "./components/student/Dashboard/LatestCourses/LatestCourses";
import LiveClass from "./components/student/Dashboard/LiveClass/LiveClass";
import MainContent from "./components/student/Dashboard/MainContent/MainContent";
import MyBooks from "./components/student/Dashboard/MyBooks/MyBooks";
import MyCourse from "./components/student/Dashboard/MyCourse/MyCourse";
import Profile from "./components/student/Dashboard/Profile/Profile";
import CanNotJoinClass from "./components/student/Pages/CanNotJoinClass/CanNotJoinClass";
import Certificate from "./components/student/Pages/Certificate/Certificate";
import AssignmentQuestionShow from "./components/student/Pages/LMS/Assignment/AssignmentQuestionShow/AssignmentQuestionShow";
import FileSubmission from "./components/student/Pages/LMS/Assignment/AssignmentQuestionShow/FileSubmission/FileSubmission";
import AssignmentQuestionShowAtaTime from "./components/student/Pages/LMS/Assignment/AssignmentQuestionShowAtaTime/AssignmentQuestionShowAtaTime";
import AssignmentSubmissionView from "./components/student/Pages/LMS/Assignment/AssignmentSubmissionView/AssignmentSubmissionView";
import AssignmentSubmitAutoCount from "./components/student/Pages/LMS/Assignment/AssignmentSubmitAutoCount/AssignmentSubmitAutoCount";
import AssignmentSubmitDone from "./components/student/Pages/LMS/Assignment/AssignmentSubmitDone/AssignmentSubmitDone";
import LectureView from "./components/student/Pages/LMS/Lectures/Lecture/LectureView/LectureView";
import LMS from "./components/student/Pages/LMS/LMS";
import SmartNoteView from "./components/student/Pages/LMS/SmartNotes/SmartNote/SmartNoteView/SmartNoteView";
import PaymentFailed from "./components/student/Pages/PaymentFailed/PaymentFailed";
import PaymentSuccess from "./components/student/Pages/PaymentSuccess/PaymentSuccess";
import TransactionTable from "./components/student/Pages/TransactionTable/TransactionTable";
import RequireAuth from "./components/user/pages/Login/RequireAuth/RequireAuth";
import User from "./components/user/User";

function App() {
  return (
        <div className="h-100">
            <Routes>
                <Route path="*" element={<User/>}></Route>
                <Route element={<Dashboard></Dashboard>}>
                    <Route path="dashboard" element={<RequireAuth> <MainContent /> </RequireAuth>}/>
                    <Route path="my-course" element={<RequireAuth> <MyCourse /> </RequireAuth>}/>
                    <Route path="live-class" element={<RequireAuth> <LiveClass /> </RequireAuth>}/>
                    <Route path="calendar" element={<RequireAuth> <Calendar /> </RequireAuth>}/>
                    <Route path="my-books" element={<RequireAuth> <MyBooks /> </RequireAuth>}/>
                    <Route path="latest-courses" element={<RequireAuth> <LatestCourses /> </RequireAuth>} />
                    <Route path="profile" element={<RequireAuth> <Profile /> </RequireAuth>} />
                    <Route path="transaction-info" element={<RequireAuth> <TransactionTable /> </RequireAuth>} />
                    <Route path="my-course/lms" element={<RequireAuth> <LMS /> </RequireAuth>} />
                    <Route path="view-submission" element={<RequireAuth> <AssignmentSubmissionView /> </RequireAuth>} />
                    <Route path="view-lecture" element={<RequireAuth> <LectureView /> </RequireAuth>} />
                    <Route path="view-smart-note" element={<RequireAuth> <SmartNoteView /> </RequireAuth>} />
                </Route>
                <Route path="payment-success" element={<PaymentSuccess />} />
                <Route path="payment-failed" element={<PaymentFailed />} />
                <Route path="assignment-question" element={<RequireAuth> <AssignmentQuestionShow /> </RequireAuth>} />
                <Route path="assignment-questions" element={<RequireAuth> <AssignmentQuestionShowAtaTime /> </RequireAuth>} />
                <Route path="submit-assignment" element={<RequireAuth> <AssignmentSubmitDone /> </RequireAuth>} />
                <Route path="submit-assignment-with-marks" element={<RequireAuth> <AssignmentSubmitAutoCount /> </RequireAuth>} />
                <Route path="image-show" element={<RequireAuth> <FileSubmission /> </RequireAuth>} />
                <Route path="join-class-issue" element={ <RequireAuth><CanNotJoinClass /></RequireAuth> }></Route>
                <Route path="certificate" element={ <RequireAuth><Certificate /></RequireAuth> }></Route>
            </Routes>
            <ToastContainer />
        </div>
    );
}

export default App;
