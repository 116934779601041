import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import StudentPageTitle from '../../../../../shared/StudentPageTitle/StudentPageTitle';

const SmartNoteView = () => {
    let location = useLocation();
    let smartNoteLink = location?.state?.smartNoteLink;
    let smart_note_pdf_file = location?.state?.smart_note_pdf_file;
    let course_id = location?.state?.course_id;
    let batch_id = location?.state?.batch_id;
    const search = location.search;
    const class_content = new URLSearchParams(search).get("class_content");


    return (
        <div className="main-content">
            <StudentPageTitle title="View Smart Note"></StudentPageTitle>
            <div className="page-content p-0">
                <div className="container-fluid">
                    <div className="my-course-section">
                    <p className='mb-4'><Link to='/my-course/lms' state={{ course_id: course_id, batch_id: batch_id, class_content: 'smart_note' }} style={{ color:'#532D80', fontWeight:'400', textDecoration:'none' }}>কোর্সের বিস্তারিত </Link> / <span style={{ color:'#918F92', fontWeight:'400' }}> স্মার্ট নোট</span></p>
                    <div className="bg-white custom-card-shadow all-assignment-section" >
                        <div className='d-flex justify-content-between align-items-center'>
                            <p className='course-total-assignment mb-0'>স্মার্ট নোটের বিস্তারিত</p>

                            {
                                class_content &&
                                <div className='pe-4'>
                                    <Link to='/my-course/lms'
                                    state={{ course_id: course_id, batch_id: batch_id, class_content: 'content_list' }} className='back-btn text-center'><i className="fa-solid fa-arrow-left-long pe-2"></i>Back to Class List</Link>
                                </div>
                            }
                        </div>
                        <div className='total-assignment-divider'></div>
                        <div className='lecture-section p-4' style={{ height:'700px' }}>
                            <embed src={`${smartNoteLink}${smart_note_pdf_file}`} type="application/pdf" width="100%" height="100%"/>

                        </div>
                    </div>
                            
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SmartNoteView;