import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import parse from 'html-react-parser';
import Loader from "../../../../shared/Loader/Loader";
import PageTitle from "../../../../shared/PageTitle/PageTitle";

const BlogDetails = () => {
    const [blogs, setBlogs] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const description = location.state.blog_details;

    useEffect(() => {
        async function fetchBlog() {
            let data = await fetch(`${process.env.REACT_APP_BASE_URL}api/blogs`);
            data = await data.json();
            setBlogs(data.data);
            setIsLoading(false);
        }
        fetchBlog(blogs);
    }, []);

    if(isLoading){
        return (<Loader/>);
    }
    
    /* const blog_image_path = blogs?.blog_image_path; */
    const title = location.state.blog_title;
    const path = location.state.img_path;
    const image = location.state.blog_img;

    return (
        <div>
            <div style={{ paddingBottom: "23rem" }}>
            <PageTitle title="Blog Details"></PageTitle>
                <div className="borderTop">
                    <div className="container">
                    <p className='pt-4'><Link to='/blog' style={{ color:'#532D80', fontWeight:'400', textDecoration:'none' }}>ব্লগ সমূহ  </Link> / <span style={{ color:'#918F92', fontWeight:'400' }}> {title} এর বিস্তারিত</span></p>
                        <p className="mt-5 mb-3" style={{ fontSize: "32px", fontWeight: "600" }} >
                            {title}
                        </p>
                        <div className="d-flex justify-content-center">
                            <img src={`${path}${image}`}  alt="" className="mb-3"/>
                        </div>
                        
                        <div
                            style={{ backgroundColor: "#EFEFEF" }}
                            className="p-4 my-5"
                        >
                            <div>{parse(description)}</div>
                        </div>
                        {/* <div>
                            <div className="row">
                                {blogs?.blogs?.map((blog) => (
                                    <div className="col-lg-3" key={blog.id}>
                                        <Link style={{ textDecoration: 'none', color: 'black' }}
                                            to="/blog-details"
                                            state={{
                                                blog_title: blog?.blog_title,
                                                blog_details:
                                                    blog?.blog_long_desc,
                                                img_path: blog_image_path,
                                                blog_img: blog?.blog_image,
                                            }}
                                        >
                                            <img
                                                src={`${blog_image_path}${blog?.blog_image}`}
                                                alt=""
                                                className="img-fluid"
                                            />
                                            <p className="blog-title">
                                                {blog?.blog_title}
                                            </p>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogDetails;
