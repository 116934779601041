import React from 'react';
import {  Modal } from 'react-bootstrap';
import './AssessmentModal.css';
import rightIcon from '../../../.../../../../../../../images/right icon.svg';
import downloadIcon from '../../../.../../../../../../../images/download icon.svg';
import { Link } from 'react-router-dom';
import AssignmentModal from './AssignmentModal/AssignmentModal';
import SmartNoteModal from './SmartNoteModal/SmartNoteModal';
import LiveClass from './LiveClass/LiveClass';
import { topicTypeImage } from '../../../../../../../../utilities/utilities';
/* import { useEffect } from 'react';
import { useState } from 'react'; */
import LectureModal from './LectureModal/LectureModal';

const AssessmentModal = (props) => {
            
            /* let location = useLocation();
            let topic_name = location?.state?.topic_name;
            const [refetchData, setRefetchData] = useState(0); */

            

            const handelTopicView = (courseID, topicID, batchID, courseSubjectID) => {
                const topicInfo = {
                    course_id: courseID,
                    user_id: localStorage.getItem("user_id"),
                    batch_id: batchID,
                    course_subject_id: courseSubjectID,
                    topic_id: topicID,
                };


                fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}view-recorded-content`,
                    {
                        method: "POST",
                        headers: {
                            "content-type": "application/json",
                            'Authorization' : `Bearer ${localStorage.getItem("auth_token")}`,
                            'SecretKey' : `${process.env.REACT_APP_API_URL_SECRET_KEY}`
                        },
                        body: JSON.stringify(topicInfo),
                    }
                )
                    .then((res) => res.json())
                    .then((data) => {
                    });

            }

            

        return (
            <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton style={{ border:'none' }}>
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex justify-content-center icon-image'>
                    {
                        topicTypeImage(props?.syllabus_content?.assesment_type)
                    }
                    
                    </div>
                    <h2 className='text-center mt-4'>{props.topicname}</h2>
                    <h3 className='text-center mb-4'>{props.lessonname}</h3>
    
                    {
                        ((props?.syllabus_content?.assesment_type === 1 || props?.syllabus_content?.assesment_type === 2 || props?.syllabus_content?.assesment_type === 3 || props?.syllabus_content?.assesment_type === 7) && props?.syllabus_content?.assignment !== null) && <AssignmentModal syllabusContent={props?.syllabus_content}/>
                    }
                    {
                        (props?.syllabus_content?.assesment_type === 5 && props?.syllabus_content?.smart_note !== null)  && <SmartNoteModal syllabusContent={props?.syllabus_content}/>
                    }
                    {
                        (props?.syllabus_content?.assesment_type === 4 && props?.syllabus_content?.live_class !== null)  && <LiveClass syllabusContent={props?.syllabus_content}/>
                    }
                    {
                        (props?.syllabus_content?.assesment_type === 6 && props?.syllabus_content?.lecture !== null)  && <LectureModal syllabusContent={props?.syllabus_content}/>
                    }

                    {
                         (props?.syllabus_content?.smart_note === null || props?.syllabus_content?.live_class === null ||  props?.syllabus_content?.assignment === null)  && <h1 className='text-center text-danger mt-5'>এখনো কোনো বিষয়বস্তু যুক্ত করা হয়নি।</h1>
                    }

                    
    
                </Modal.Body>
                <Modal.Footer style={{ border:'none', justifyContent:'center', paddingBottom:'44px' }}>
                    
                    {/* start assignment Button */}
                    {
                        ((props?.syllabus_content?.assesment_type === 1 || props?.syllabus_content?.assesment_type === 2 || props?.syllabus_content?.assesment_type === 3 || props?.syllabus_content?.assesment_type === 7) && props?.syllabus_content?.assignment !== null) &&
                        
                        (props?.syllabus_content?.assignment?.question_show_mode === 1 && (props?.syllabus_content?.assignment?.no_of_attempts - props?.syllabus_content?.assignment?.total_attempts > 0)) ? 
                        <Link to='/assignment-question' state={{ assignment_id: props?.syllabus_content?.assignment?.id }} 
                                                        onClick={() => handelTopicView(props?.syllabus_content?.assignment?.course_id, 
                                                                props?.topicid,
                                                                props?.syllabus_content?.assignment?.batch_id, 
                                                                props?.syllabus_content?.assignment?.subject_id)}  className='btn-assignment-start text-center px-5 d-flex align-items-center'><span className='pt-1'>শুরু করুন</span> <span><img src={rightIcon} alt="" className='ms-2' /></span></Link>
                        : props?.syllabus_content?.assignment?.question_show_mode === 2 ?
                        <Link to='/assignment-questions' state={{ assignment_id: props?.syllabus_content?.assignment?.id}} 
                                                        onClick={() => handelTopicView(props?.syllabus_content?.assignment?.course_id, 
                                                                props?.topicid,
                                                                props?.syllabus_content?.assignment?.batch_id, 
                                                                props?.syllabus_content?.assignment?.subject_id)}  className='btn-assignment-start text-center px-5 d-flex align-items-center'><span className='pt-1'>শুরু করুন</span> <span><img src={rightIcon} alt="" className='ms-2' /></span></Link>
                        : ''
                    }
                    {/* submission assignment Button */}
                    {
                        ((props?.syllabus_content?.assesment_type === 1 || props?.syllabus_content?.assesment_type === 2 || props?.syllabus_content?.assesment_type === 3 || props?.syllabus_content?.assesment_type === 7) && props?.syllabus_content?.assignment !== null) && (props?.syllabus_content?.assignment?.no_of_attempts >= props?.syllabus_content?.assignment?.total_attempts && props?.syllabus_content?.assignment?.total_attempts !== 0) && 
                            <Link to='/view-submission' 
                                state={{ assignment_id: props?.syllabus_content?.assignment?.id,
                                       total_attempt: props?.syllabus_content?.assignment?.total_attempts,
                                       no_of_attempts: props?.syllabus_content?.assignment?.no_of_attempts,
                                       subject: props?.syllabus_content?.assignment.subject_name,
                                       total_questions: props?.syllabus_content?.assignment?.total_questions,
                                       total_marks: props?.syllabus_content?.assignment?.total_marks }}  
                                className='btn-submission-syllabus text-center px-5 d-flex align-items-center'>
                                <span className='pt-1'>সাবমিশন দেখুন</span> <span><img src={rightIcon} alt="" className='ms-2' style={{ filter: 'brightness(0) saturate(100%) invert(23%) sepia(44%) saturate(1871%) hue-rotate(240deg) brightness(82%) contrast(97%)' }} /></span>
                            </Link>
                        
                    }
                    

                    {/* Join Class Button */}
                    {
                        
                        (props?.syllabus_content?.assesment_type === 4 && props?.syllabus_content?.live_class !== null) && 
                         <a onClick={() => handelTopicView(props?.syllabus_content?.live_class?.course_id, 
                                                            props?.topicid,
                                                            props?.syllabus_content?.live_class?.batch_id, 
                                                            props?.syllabus_content?.live_class?.course_subject_id)} className='btn-assignment-start text-center px-5 d-flex align-items-center' href={`${process.env.REACT_APP_BASE_URL}student/join-live-class?live_class_id=${props?.syllabus_content?.live_class?.id}&user_id=${localStorage.getItem("user_id")}&course_id=${props?.syllabus_content?.live_class?.course_id}&course_subject_id=${props?.syllabus_content?.live_class?.course_subject_id}&batch_id=${props?.syllabus_content?.live_class?.batch_id}`}>ক্লাসে যোগ দিন</a>
                        
                    }

                    {/* Lecture Button */}
                    {
                        (props?.syllabus_content?.assesment_type === 6 && props?.syllabus_content?.lecture !== null) && <Link to='/view-lecture?class_content=lecture'
                                state={{ lecture_id: props?.syllabus_content?.lecture?.id,
                                    lesion_name: props.lessonname,
                                    topic_name: props.topicname,
                                    course_id: props?.syllabus_content?.lecture?.course_id,
                                    batch_id: props?.syllabus_content?.lecture?.batch_id
                                }} 
                                onClick={() => handelTopicView(props?.syllabus_content?.lecture?.course_id, 
                                                                props?.topicid,
                                                                props?.syllabus_content?.lecture?.batch_id, 
                                                                props?.syllabus_content?.lecture?.course_subject_id)}
                                className='btn-assignment-start text-center px-5 d-flex align-items-center'>লেকচারের দেখুন</Link>
                    }

                    {/* Smart Note Button */}
                    {
                        (props?.syllabus_content?.assesment_type === 5 && props?.syllabus_content?.smart_note !== null) && <Link to='/view-smart-note?class_content=smart-note'
                                state={{ smartNoteLink: props?.syllabus_content?.smart_note_pdf_link,
                                    smart_note_pdf_file: props?.syllabus_content?.smart_note?.smart_note_pdf_file,
                                    course_id: props?.syllabus_content?.smart_note?.course_id,
                                    batch_id: props?.syllabus_content?.smart_note?.batch_id
                                }} 
                                onClick={() => handelTopicView(props?.syllabus_content?.smart_note?.course_id, 
                                                        props?.topicid,
                                                        props?.syllabus_content?.smart_note?.batch_id, 
                                                        props?.syllabus_content?.smart_note?.subject_id)} className='btn-assignment-start text-center px-5 d-flex align-items-center'><span className='pt-1'>স্মার্ট নোট দেখুন </span> <span><img src={downloadIcon} alt="" className='ms-2' /></span></Link>
                    }
                    
                    
                    
                    
                </Modal.Footer>
            </Modal>
        );
};

export default AssessmentModal;