import React from 'react';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import { useEffect, useState } from 'react';
import './Calendar.css';
import ActiveDate from './ActiveDate/ActiveDate';
import { dateConverterEnglish, eventDate } from '../../../../utilities/utilities';
import StudentPageTitle from '../../shared/StudentPageTitle/StudentPageTitle';

const Calendar = () => {
    const [events, setEvents] = useState([]);
    /* const [currentDate, setCurrentDate]= useState(new Date()); */
    const [clickedDate, setClickedDate]= useState();
    const [eventsByDates, setEventsByDates]= useState([]);
    const [clickedDataShow, setClickedDataShow]= useState(false);


    /* testing api count */
    // const [eventsByDate, setEventsByDate]= useState([]);
    // const [date, setDate]= useState(new Date);
    // const [currentDate, setCurrentDate]= useState(new Date);
    // const [ok, setOK]= useState();

    // /* if(date){
    //     let clickedDate = new Date(date);
    //     let today = new Date(clickedDate.toISOString());
    //     //console.log((today?.getFullYear())+ '-' + (today?.getMonth()+1) + '-' +(today?.getDate()));
    //     setOK((today?.getFullYear())+ '-' + (today?.getMonth()+1) + '-' +(today?.getDate()));
    //     console.log('after click'+ ok)
        
    // } */

    // useEffect(()=>{
    //     let clickedDate = new Date(date);
    //     let today = new Date(clickedDate.toISOString());
    //     //console.log((today?.getFullYear())+ '-' + (today?.getMonth()+1) + '-' +(today?.getDate()));
    //     setCurrentDate((today?.getFullYear())+ '-' + (today?.getMonth()+1) + '-' +(today?.getDate()));
    //     console.log('after click'+ ok)
    // },[date])

     /* useEffect(()=>{
        function join(t, a, s) {
            function format(m) {
               let f = new Intl.DateTimeFormat('en', m);
               return f.format(t);
            }
            return a.map(format).join(s);
         }
         let a = [{year: 'numeric'}, {month: 'numeric'}, {day: 'numeric'}];
         setCurrentDate(join(currentDate, a, '-'));
         //console.log(join(currentDate, a, '-'))
    },[]) */

    /* let date = eventDate(currentDate);  */
    let date = eventDate(new Date()); 

    //console.log(currentDate)
    useEffect(()=>{
        //get all events
        async function fetchEvents(){
            let response = await fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}calendar-events?user_id=${localStorage.getItem("user_id")}`,{
                    method:'GET',
                    headers:{
                        'content-type' : 'application/json',
                        'accept' : 'application/json',
                        'Authorization' : `Bearer ${localStorage.getItem("auth_token")}`,
                        'SecretKey' : `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
                    },
                })
            response = await response.json();
            setEvents(response.data);
        }
        fetchEvents(date)

        //get current events
        
        async function fetchEventByDate(date){
            let response = await fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}calendar-events-by-date?date=${date}&user_id=${localStorage.getItem("user_id")}`,{
                    method:'GET',
                    headers:{
                        'content-type' : 'application/json',
                        'accept' : 'application/json',
                        'Authorization' : `Bearer ${localStorage.getItem("auth_token")}`,
                        'SecretKey' : `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
                    },
                })
            response = await response.json();
            setEventsByDates(response?.data);
            /* console.log(response); */
        }
        fetchEventByDate(date)
    },[date])


    /* get events after clicked */
    if(clickedDate && clickedDataShow)
        {
            let newDate = new Date(clickedDate);
            let getDate=(eventDate(newDate.toISOString()));
            async function fetchEventByDate(test){
                let response = await fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}calendar-events-by-date?date=${getDate}&user_id=${localStorage.getItem("user_id")}`,{
                    method:'GET',
                    headers:{
                        'content-type' : 'application/json',
                        'accept' : 'application/json',
                        'Authorization' : `Bearer ${localStorage.getItem("auth_token")}`,
                        'SecretKey' : `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
                    },
                })
                response = await response.json();
                setEventsByDates(response.data);
                setClickedDataShow(false);
            }
            fetchEventByDate(getDate)
        }

    
    return (
        <div className="main-content" style={{ backgroundColor: "#F4F1EB" }}>
        <StudentPageTitle title="Calender"></StudentPageTitle>
            <div className="page-content p-0">
                <div className="container-fluid">
                    <div className='my-course-section'>
                        <div className="row">
                            <div className="col-lg-4">
                                <p className='my-course-title text-center'> {eventsByDates[0]?.start === undefined ? dateConverterEnglish(Date.now()): dateConverterEnglish(eventsByDates[0]?.start)}</p>
                                <div className="row gy-3 mt-3">
                                {
                                    eventsByDates?.map((eventByDate, index) => <ActiveDate eventByDate={eventByDate} key={index}/> )
                                } 
                                </div>
                            </div>

                            <div className="col-lg-8">
                                <FullCalendar
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                                initialView="dayGridMonth"
                                eventTimeFormat= {{ 
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12:true
                                }}
                                headerToolbar={{
                                    left: 'prev,next today',
                                    center: 'title',
                                    right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                                    }}
                                dateClick={
                                    (arg) => {
                                        setClickedDate(arg.date);
                                        setClickedDataShow(true);
                                    }
                                }
                                dayMaxEvents = {2}
                                events={events}
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default Calendar;