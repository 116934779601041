import React from 'react';
import './Lessons.css';

import Topics from './Topics/Topics';

const Lessons = ({lesson, LessonSerial, lessons, handelGetCertificate, course_name,user_name, setShowCertificate}) => {
/* const [stop, setStop] = useState(true); */
    /* useEffect(() => {
        // Call the function here
        if (lessons[lessons.length-1]?.topics[lesson?.topics?.length - 1]?.is_viewed) {
            handelGetCertificate();
        };
    }, [lessons, handelGetCertificate, lesson?.topics?.length]); */
    return (
        <div>

            <div className="accordion-item lesson-accordion-body">
            <h3 className="accordion-header change">
                <button
                    className={`accordion-button ${LessonSerial === 0 ? '' : 'collapsed'} btn-lesson`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#faq-content-${LessonSerial}-${lesson?.lesson_id}`}
                >
                    <span className='fw-500'>{lesson?.lesson_name}</span>
                </button>
            </h3>
            
            <div id={`faq-content-${LessonSerial}-${lesson?.lesson_id}`}
                    className={`accordion-collapse collapse ${LessonSerial === 0 ? 'show' : ''}`} 
                    data-bs-parent="#syllabus-list">
                <div className='total-assignment-divider'></div>
                <div className='mt-3 px-2 table-responsive'>
                    <table className='table table-borderless'>

                        <tbody>
                            
                        {   
                            lesson?.topics?.length === 0 
                                ?<tr>
                                    <td colSpan={5}>
                                        <p className="d-flex justify-content-center mb-0 w-100" style={{ fontSize: "20px", fontWeight: "600" }}>এখনো কোনো বিষয়বস্তু যুক্ত করা হয়নি।</p> 
                                    </td>
                                </tr> 
                                    :
                                lesson?.topics?.map((topic, index) => 
                                <Topics  
                                    unlock={ lesson?.topics[index-1]?.is_viewed === 1 && lesson?.topics[index]?.is_viewed === 0 ? true :  false }
                                    key={topic?.topic_id} 
                                    topic={topic} 
                                    topicSerial={index} 
                                    LessonSerial={LessonSerial} 
                                    lessonName={lesson?.lesson_name}
                                    lessons={lessons}
                                    topicslist={lesson?.topics}
                                >
                                </Topics>)
                        }

                        {/* {
                            lessons[lessons.length-1]?.topics[lesson?.topics?.length - 1]?.is_viewed === 1 &&
                                
                                <tr>
                                    <td colSpan={5} className='text-center'>
                                        {
                                            setShowCertificate(true)
                                        }
                                    </td>
                                </tr>
                                
                            
                        } */}
                        {/* {
                            lessons[lessons.length-1]?.topics[lesson?.topics?.length - 1]?.is_viewed == 1 &&
                            <tr>
                                <td colSpan={5} className='text-center'>
                                    <div className='d-flex justify-content-center'>
                                        <Link to={`/certificate`}
                                        state={{
                                                course_name: course_name,
                                                user_name: user_name
                                            }}
                                            className='btn-submission-syllabus text-center d-flex align-items-center me-3'>Get Certificate</Link>
                                    </div>
                                </td>
                            </tr>
                        } */}
                        </tbody>
                    </table>
                </div>
            </div>
            </div>



        </div>
    );
};

export default Lessons;