import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './AssignmentSubmitAutoCount.css';

const AssignmentSubmitAutoCount = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const assignment_name = location?.state?.assignment_name;
    const wrong_answer = location?.state?.wrong_answer;
    const correct_answer = location?.state?.correct_answer;
    const get_marks = location?.state?.get_marks;
    const total_marks = location?.state?.total_marks;
    const course_id = location?.state?.course_id;
    const batch_id = location?.state?.batch_id;

    const handelGoToDashboard =()=>{
        //navigate("/dashboard")
        navigate("/my-course/lms", {
            state: {
                course_id: course_id,
                batch_id: batch_id
            },
        });
    }
    return (
        <div>
            <div className='d-flex justify-content-between align-items-center p-4'>
                <div className='btn-assignment-submit-and-close'>
                    <button type="button" className="btn-close" aria-label="Close" onClick={handelGoToDashboard}></button>
                </div>
                <p className='text-center mb-0 show-assignment-name'>{assignment_name}</p>
                <div></div>
            </div>
            <div className='total-assignment-divider'></div>

            <div className='container mt-3'>


            <div className='d-flex justify-content-center align-items-center' style={{ height:'80vh' }}>

                <div>
                    <div className='auto-count-score'>
                        <p className='text-center mb-0 score-title'>Your Score</p>
                        <p className='text-center mb-0 get-score'>{get_marks}</p>
                    </div>
                    <p className='complete-title text-center'>Completed Successfully!</p>
{/*                     <p className='get-result-notice text-center'>We have received your submission. Mentor will evaluate the paper & publish the <br/> result within a very shotrt time.</p>
 */}                    
                    <div className='d-flex justify-content-center score-short-description mb-3 w-100'>
                        <div className='w-100'>
                            <div className='d-flex justify-content-between w-100 score-short-description-total'>
                                <p className='mb-0' style={{ color:'#656367', fontWeight:'400' }}>Total Marks</p>
                                <p className='mb-0 pb-1' style={{ color:'#000', fontWeight:'500' }}>{total_marks}</p>
                            </div>
                            <div className='d-flex justify-content-between w-100 score-short-description-total mt-2'>
                                <p className='mb-0' style={{ color:'#656367', fontWeight:'400' }}>Total Questions</p>
                                <p className='mb-0 pb-1' style={{ color:'#000', fontWeight:'500' }}>{correct_answer + wrong_answer}</p>
                            </div>
                            <div className='d-flex justify-content-between w-100 score-short-description-total mt-2'>
                                <p className='mb-0' style={{ color:'#656367', fontWeight:'400' }}>Right Answer(s)</p>
                                <p className='mb-0 pb-1 text-success' style={{ fontWeight:'500' }}>{correct_answer}</p>
                            </div>
                            <div className='d-flex justify-content-between w-100 mt-2'>
                                <p className='mb-0' style={{ color:'#656367', fontWeight:'400' }}>Wrong Answer(s)</p>
                                <p className='mb-0 pb-1 text-danger' style={{ fontWeight:'500' }}>{wrong_answer}</p>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <Link to='/my-course/lms' state={{ 
                            course_id: course_id,
                            batch_id: batch_id
                         }} className='btn-submitAssignment-to-dashboard px-4 py-2' onClick={handelGoToDashboard}>Go to dashboard</Link>
                    </div>
                </div>
            </div>
            </div>
        </div>
    );
};

export default AssignmentSubmitAutoCount;