import React, { useState } from 'react';
import './AssignmentQuestionShow.css';
/* import questionClock from '../../../../../../images/question-time-clock-fill.svg' */
import questionClockGray from '../../../../../../images/due-clock-fill_gray.svg'
import questionCircle from '../../../../../../images/question-circle-fill.svg'
import { useEffect } from 'react';
import MultipleChoice from './MultipleChoice/MultipleChoice';
import MultipleAnswer from './MultipleAnswer/MultipleAnswer';
import TrueFalse from './TrueFalse/TrueFalse';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useLocation, useNavigate } from 'react-router-dom';
import BroadQuestion from './BroadQuestion/BroadQuestion';
import FileSubmission from './FileSubmission/FileSubmission';
import StudentPageTitle from '../../../../shared/StudentPageTitle/StudentPageTitle';



const AssignmentQuestionShow = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const assignment_id = location.state.assignment_id;
    const [questions, setQuestions] = useState([]);
    const [startSlice, setStartSlice] = useState(0);
    const [endSlice, setEndSlice] = useState(1);
    const [totalAnswer, setTotalAnswer] = useState([]);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const [test, setTest] = useState(true);
    const [startTime, setStartTime] = useState(true);
/*     const [showInstructionModal, setShowInstructionModal] = useState(true);

    const [showModal, setShowModal] = useState(false); */


    useEffect(()=>{
            async function fetchQuestionApi(){
                let response = await fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}assignment-questions?assignment_id=${assignment_id}&user_id=${localStorage.getItem("user_id")}`,{
                    method:'GET',
                    headers:{
                        'content-type' : 'application/json',
                        'accept' : 'application/json',
                        'Authorization' : `Bearer ${localStorage.getItem("auth_token")}`,
                        'SecretKey' : `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
                    },
                })
                response = await response.json();
                setQuestions(response.data);
            }
            fetchQuestionApi();
            /* const handler = (e) => {
                e.preventDefault();
                if (false) {
                    return ;
                }
                e.returnValue = true;
            };

                window.addEventListener("beforeunload", handler);
                return () => window.removeEventListener("beforeunload", handler);
                 */

            const handleTabClose = event => {
            event.preventDefault();
      return (event.returnValue = 'Are you sure you want to exit?');
    };

    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
                
    },[assignment_id]);




    
    /* Assignment timer */
    if(questions?.assignment_duration && startTime){
            setStartTime(false);
            let sec = (questions?.assignment_duration * 60) - 2;
            //let sec = 10;
            let countDiv = document.getElementById("remain-time");
            let countDown = setInterval(function () {   
                perSecond();
            }, 1000);
        function perSecond() {
            let min = Math.floor(sec / 60),
            remSec  = sec % 60;
                if (remSec < 10) {
                    remSec = '0' + remSec;
                }
            if (min < 10) {
                min = '0' + min;
            }
            countDiv.innerHTML = min + ":" + remSec;
            
            if (sec > 0) {
                sec = sec - 1;   
            } else {
                setShow(true);
                clearInterval(countDown);
        
                countDiv.innerHTML = '00:00';
                    
            }
        }

    }


    let answer; //get answer from student variable

    /* show question one by one */
    const handelSlice = () =>{
        setStartSlice(startSlice + 1);
        //if(endSlice < questions?.questions?.length )
            setEndSlice(endSlice + 1);
        if(answer)
            setTotalAnswer([...totalAnswer, answer])
        answer = ''
        
        let element = document.getElementById("myprogressBar");
        if(questions?.questions?.length){
            let complete = (100*endSlice)/questions?.questions?.length;
            element.style.width = complete + '%';
        }

    }
    
    /* get answer from MCQ and TF */
    const handelRadioButton = (answer_id, question_id) =>{        
         answer = {
            "question_id" : question_id,
            "answer_id" : answer_id
        };
    }
    /* get answer from broad */
    const handelBroadQuestion = (broad_ans, question_id) =>{        
         answer = {
            "question_id" : question_id,
            "broad_ans" : broad_ans,
            "answer_id" : 0
        };
    }
    /* get answer from filesubmission */
    const handelFileSubmission = (answerImage, question_id) =>{        
         answer = {
            "question_id" : question_id,
            "files" : answerImage,
            "answer_id" : 0
        };
    }

    /* get answer from MA*/
    const handelCheckBox = (answer_id, question_id, addOrRemove) =>{
        if(addOrRemove === 1){
            answer = {
                "question_id" : question_id,
                "answer_id" : answer_id
            };
            if(answer)
                setTotalAnswer([...totalAnswer, answer])
            answer = ''
        }else{
            setTotalAnswer(totalAnswer.filter((item) => item.answer_id !== answer_id));
        }
    }


    /* modal show with submit option after complete assignment */
    if(endSlice > questions?.questions?.length && test){
        setShow(true);
        setTest(false);
    }

    /* save assignment */
    const handelSubmitAssignment = () =>{
            
        const totalAnswers = {
            attempt_id : questions?.attempt_id,
            assignment_id : assignment_id,
            question_answer : totalAnswer
        }

            fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}save-assignment`,{
                method: 'POST',
                headers:{
                    'content-type' : 'application/json'
                },
                body: JSON.stringify(totalAnswers),
            })
            .then(res => res.json())
            .then(data =>{
                if(data.message_type === 'success'){
                    setShow(false)

                    if(questions?.result_display === 2)
                    {
                        navigate("/submit-assignment", {
                            state: {
                                assignment_name: questions?.assignment_name,
                                course_id: questions?.course_id,
                                batch_id: questions?.batch_id
                            }
                        })
                    }

                    else if(questions?.result_display === 3)
                    {
                        let wrong_answer = 0;
                        let correct_answer = 0;
                        let get_marks = 0;
                        let wrong_question_marks = 0;
                        let question_marks;
                        let arr = [];

                        data?.data.forEach(question => {
                        let initial_wrong_answer = 0;
                            question?.options.forEach(option => {
                                let initial_right_answer = 0;
                                totalAnswer.forEach(answer => {
                                    if(parseInt(answer.answer_id) === option.id && answer.question_id === option.question_id){
                                        initial_right_answer = 1;
                                        if (option.is_correct === 1) {
                                            return ;
                                        } else {
                                            initial_wrong_answer = 1;
                                            return ;
                                        }
                                    }
                                });
                                if ((initial_right_answer === 0) && (option.is_correct === 1)) {
                                    initial_wrong_answer = 1;
                                }
                            });
                            if (initial_wrong_answer === 1) {
                                wrong_answer++;
                                wrong_question_marks= wrong_question_marks + parseInt(question.marks);
                                question_marks = {
                                    "question_id" : question.id,
                                    "marks" : 0
                                };
                                arr.push(question_marks);
                            } else {
                                correct_answer++;
                                get_marks = get_marks + parseInt(question.marks);
                                question_marks = {
                                    "question_id" : question.id,
                                    "marks" : question.marks
                                };
                                arr.push(question_marks);
                            }
                        });
                        const student_marks_detail = {
                            attempt_id : questions?.attempt_id,
                            obtained_marks : get_marks,
                            question_obtained_marks : arr
                        }

                        fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}save-assignment-marking`,{
                            method: 'POST',
                            headers:{
                                'content-type' : 'application/json'
                            },
                            body: JSON.stringify(student_marks_detail),
                        })
                        .then(res => res.json())
                        .then(data => console.log(''))

                        navigate("/submit-assignment-with-marks", {
                            state: {
                                assignment_name: questions?.assignment_name,
                                wrong_answer: wrong_answer,
                                correct_answer: correct_answer,
                                get_marks: get_marks,
                                total_marks: get_marks + wrong_question_marks,
                                course_id: questions?.course_id,
                                batch_id: questions?.batch_id

                            }
                        }) 
                    }
                    else{
                        navigate("/submit-assignment", {
                            state: {
                                assignment_name: questions?.assignment_name,
                                course_id: questions?.course_id,
                                batch_id: questions?.batch_id
                            }
                        })
                    }

                }else{
                    console.log('error');
                }
            })
    }


/*     const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false); */
    

    return (
        <div>
            <div className='d-flex justify-content-center align-items-center p-4'>
            <StudentPageTitle title="Assignment Question"></StudentPageTitle>
                {/* <div className='btn-assignment-submit-and-close'>
                    <button type="button" className="btn-close" aria-label="Close"></button>
                </div> */}
                <p className='text-center mb-0 show-assignment-name'>{questions?.assignment_name}</p>
                {/* <div></div> */}
            </div>
            <div className='total-assignment-divider'></div>

            

            <div className='container mt-3'>

                <div className='d-flex justify-content-between mb-3'>
                    <span className='question-count px-2 py-1 d-flex align-items-center' style={{ width:'16rem' }}>
                        <div><img src={questionClockGray} className='img-fluid' alt="" /></div>
                        <p className='remain-time ms-1 mb-0'>Assignment Timer <span style={{ fontSize:'22px', fontWeight:'600' }} id='remain-time'>{questions?.assignment_duration}:00</span></p>
                    </span>
                    <span className='question-count px-2 py-1 d-flex align-items-center' style={{ width:'10rem' }}>
                        <div><img src={questionCircle} className='img-fluid' alt="" /></div>
                        {/* <p className='remain-question ms-1 mb-0'>{endSlice > questions?.questions?.length ?  questions?.questions?.length : endSlice}/{questions?.questions?.length}</p> */}
                        <p className='remain-question ms-1 mb-0'><span style={{ fontSize:'22px', fontWeight:'600' }}>{questions?.questions?.length}</span> Questions</p>
                    </span>
                </div>

                <div className="progress mt-1 mb-3" style={{borderRadius:'15px' }}>
                    <div className="progress-bar" role="progressbar" id="myprogressBar" style={{ width: '0%', borderRadius:'15px', backgroundColor:'#46C35F' }} aria-valuenow="5" aria-valuemin="0" aria-valuemax="100"></div>
                </div>

                
                {/* <div className='d-flex justify-content-between mb-4'>
                    <span className='question-time px-2 py-1 d-flex align-items-center'>
                        <div><img src={questionClock} className='img-fluid' alt="" /></div>
                        <p className='remain-time ms-1 mb-0' id='remain-time'>{questions?.assignment_duration}:00</p>
                    </span>
                    <span className='question-count px-2 py-1 d-flex align-items-center'>
                        <div><img src={questionCircle} className='img-fluid' alt="" /></div>
                        <p className='remain-question ms-1 mb-0'>{endSlice > questions?.questions?.length ?  questions?.questions?.length : endSlice}/{questions?.questions?.length}</p>
                    </span>
                    <p style={{ fontWeight:'400' }}>Question No #{endSlice}</p>
                    <p style={{ fontWeight:'400' }}>Question Type: </p>
                </div> */}
                    <form>
                        {
                            questions?.questions?.slice(startSlice,endSlice).map((question, index) =>
                                (
                                    question?.question_type_id === 1 ? <MultipleChoice question={question} key={question?.id} handelRadioButton={handelRadioButton} endSlice={endSlice}/> : 

                                    question?.question_type_id === 2 ? <MultipleAnswer question={question} key={question?.id} handelCheckBox={handelCheckBox} endSlice={endSlice}/>:

                                    question?.question_type_id === 3 ? <TrueFalse question={question} key={question?.id} handelRadioButton={handelRadioButton} endSlice={endSlice}/>:

                                    question?.question_type_id === 4 ? <BroadQuestion question={question} key={question?.id} handelBroadQuestion={handelBroadQuestion} endSlice={endSlice}/> : 
                                    
                                    <FileSubmission question={question} key={question?.id} handelFileSubmission={handelFileSubmission} endSlice={endSlice}/>
                                )
                            )
                        }
                    </form>


                    {/* <>
                    <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false}>
                        <Modal.Header closeButton>
                        <Modal.Title>Modal heading</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleCloseModal}>
                            Save Changes
                        </Button>
                        </Modal.Footer>
                    </Modal>
                    </> */}

                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <Modal.Title>Submit</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want submit your Assignment?
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="mb-0 btn-next-question text-center text-white" style={{ backgroundColor: '#532d80' }} onClick={handelSubmitAssignment}>জমা দাও</Button>
                    </Modal.Footer>
                </Modal>

                <div className="fixed-bottom">
                    <div className='total-assignment-divider'></div>
                    <div className='container pt-2 pb-4 d-flex justify-content-between bg-white'>
                        <span></span>
                        
                            <span className='mb-0 btn-next-question text-center' style={{ cursor: 'pointer' }} onClick={handelSlice}>পরবর্তী</span>
                        
                    </div>
                </div>


            </div>
        </div>
    );
};

export default AssignmentQuestionShow;