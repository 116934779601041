import React, { useState } from 'react';
import { formatAMPMDate } from '../../../../../../utilities/utilities';

const TimeSlot = ({slot,slots}) => {
    const [slotOption, seSlotOption] = useState(slots[0].id);
        /* if(orientationCourseLists[0]?.id && selectedOptionStart){
            setSelectedOption(orientationCourseLists[0]?.id);
            setSelectedOptionStart(false);
        } */

        const handelTimeSlotChange = (event) => {
            seSlotOption(parseInt(event.target.value));
            console.log(event.target.value);
        };

    return (
        <div className="col-lg-2" key={slot?.orientation_class_time}>
            <div className="slot-list">
                <input
                type="radio"
                name={`slot-list-${slot?.id}`}
                id={`slot-list-${slot?.id}`}
                value={slot?.id}
                checked={slotOption === slot?.id}
                onChange={handelTimeSlotChange}
                />
                <label htmlFor={`slot-list-${slot?.id}`}> {formatAMPMDate(slot?.orientation_class_time)}</label>
                
            </div>
        </div>
    );
};

export default TimeSlot;