import React from "react";
import {
    dateConverter,
    dateConverterWithOutYear,
    englishToBengaliNumberFormat,
} from "../../../../utilities/utilities.js";
/* import { LMSContext } from "../../Pages/LMS/LMS"; */
import PartialPayment from "../PartialPayment/PartialPayment";
import liveType from "../../../../images/live type.svg";
import recordType from "../../../../images/record type.svg";
import defaultImage from "../../../../images/default_course_image.jpg";
import { Link } from "react-router-dom";
import './LmsPrimarySection.css';
const LmsPrimarySection = ({ courseBasicInfo }) => {
    /* const [courseBasicInfo, setCourseBasicInfo] = useState({});
    const {batch_id, course_id} = useContext(LMSContext);

    useEffect(()=>{
        fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}course-global-infos?course_id=${course_id}&batch_id=${batch_id}&user_id=${localStorage.getItem("user_id")}`)
            .then((res) => res.json())
            .then((data) => setCourseBasicInfo(data.data));
    },[]) */

    /* console.log(courseBasicInfo); */
    return (
        <div>
            <p className="mb-4">
                <Link to='/my-course' style={{ color: "#532D80", fontWeight: "400", textDecoration:'none' }}>সকল কোর্স</Link> / <span style={{ color: "#918F92", fontWeight: "400" }}>কোর্সের বিস্তারিত</span>
            </p>
            <div className="course-details-summary custom-card-shadow d-flex justify-content-between align-items-center">
                <div>
                    <div className="d-flex align-items-center mb-4">
                        {courseBasicInfo?.course_type === "Live course" ? (
                            <img src={liveType} className="img-fluid" alt="" />
                        ) : courseBasicInfo?.course_type ===
                          "Recorded course" ? (
                            <img
                                src={recordType}
                                className="img-fluid"
                                alt=""
                            />
                        ) : (
                            ""
                        )}
                        <p className="purchased_course_name_lms mb-0">
                            {courseBasicInfo?.course_name}
                        </p>
                    </div>

                    <div className="d-flex align-items-center">
                        <div>
                            <p
                                className="mb-1"
                                style={{
                                    color: "#00000080",
                                    fontWeight: "400",
                                }}
                            >
                                ব্যাচ
                            </p>
                            <div className="d-flex align-items-center">
                                <p className="course-batch-name mb-0">
                                    {courseBasicInfo?.batch_name}
                                </p>
                                <div className="course-card-student-pointer ms-4"></div>
                                <p className="course-total-student mb-0 ms-2">
                                    <span className="balooda-medium">
                                        {englishToBengaliNumberFormat(
                                            courseBasicInfo?.total_students
                                        )}
                                    </span>
                                    জন ছাত্র/ছাত্রী
                                </p>
                            </div>
                        </div>

                        <div className="course-details-divider mx-3"></div>

                        <div>
                            <p
                                className="mb-1"
                                style={{
                                    color: "#00000080",
                                    fontWeight: "400",
                                }}
                            >
                                কোর্সের সময়সীমা
                            </p>
                            <div className="d-flex align-items-center">
                                <p className="course-total-student mb-0 balooda-medium">
                                    {dateConverterWithOutYear( courseBasicInfo?.course_start_date)}{" "}-{" "}
                                    {dateConverter(courseBasicInfo?.course_end_date)}
                                </p>
                            </div>
                        </div>

                        {courseBasicInfo?.subscription_details?.due_amount >
                            0 && (
                            <>
                                <div className="course-details-divider mx-3"></div>
                                <div>
                                    <p
                                        className="mb-1"
                                        style={{
                                            color: "#00000080",
                                            fontWeight: "400",
                                            fontSize: "18px",
                                        }}
                                    >
                                        মেয়াদ বাকি আছে 
                                        <span className="text-dark balooda-medium mx-1">
                                            {englishToBengaliNumberFormat(
                                                courseBasicInfo
                                                    ?.subscription_details
                                                    ?.course_access_time_remaining
                                            )}
                                        </span> 
                                        দিন
                                    </p>
                                    <div className="d-flex align-items-center">
                                        <button
                                            className="btn btn-danger px-3 p-2"
                                            style={{ borderRadius: "8px" }}
                                            type="button"
                                            data-bs-toggle="offcanvas"
                                            data-bs-target="#offcanvasPartialPayment"
                                            aria-controls="offcanvasPartialPayment"
                                        >
                                            সাবস্ক্রিপশনের মেয়াদ বৃদ্ধি করুন{" "}
                                            <i className="fas fa-arrow-right ms-3"></i>
                                        </button>
                                        <PartialPayment
                                            courseBasicInfo={courseBasicInfo}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>

                <div className="primary-section-img">
                    {courseBasicInfo?.course_picture !== undefined &&
                    courseBasicInfo?.course_picture !== null ? (
                        <img
                            src={`${courseBasicInfo?.course_image_path}${courseBasicInfo?.course_picture}`}
                            alt=""
                            className="img-fluid"
                        />
                    ) : (
                        <img
                            src={`${defaultImage}`}
                            alt=""
                            className="img-fluid"
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default LmsPrimarySection;
