import React from 'react';
import './BookCard.css';

import noBookImgFound from "../../../../../images/default_book_image.jpg";
import defaultPerson from "../../../../../images/Default Person Avatar.png";
import mobileIcon from '../../../../../images/mobile_icon.svg';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const BookCard = ({purchasedEbook, ebook_image_path, author_image_path}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <div className='col-xxl-2 col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12' onClick={handleShow} style={{ cursor:'pointer' }}>
            <div className='purchase-book-card'>
                <div>
                    {
                        (purchasedEbook?.book_image !== undefined && purchasedEbook?.book_image !== null) ? 
                        <img src={`${ebook_image_path}${purchasedEbook?.book_image}`} alt="" className='img-fluid' style={{ borderRadius:'16px', border:'2px solid #FFEBB0' }}/>
                        : 
                        <img src={noBookImgFound} alt="" className='img-fluid' style={{ borderRadius:'16px', border:'2px solid #FFEBB0' }}/>

                    }
                    {/* <img src={`${ebook_image_path}${purchasedEbook?.book_image}`} alt="" className='img-fluid' style={{ borderRadius:'16px', border:'2px solid #FFEBB0' }}/> */}
                </div>
                <div className='mt-3'>
                    <p className='mb-2 purchase-book-name'>{purchasedEbook?.book_title}</p>
                    
                    <div className='d-flex align-items-center'>
                        <div className="avatar-group ">
                            <div className="avatar" >
                                {
                                    (purchasedEbook?.writter_image !== undefined && purchasedEbook?.writter_image !== null) ? 
                                    <img src={`${author_image_path}${purchasedEbook?.writter_image}`} alt="" style={{ borderRadius:'50%' }}/>
                                    : 
                                    <img src={defaultPerson} alt="" style={{ borderRadius:'50%' }}/>

                                }
                                {/* <img src={`${author_image_path}${purchasedEbook?.writter_image}`} alt="" style={{ borderRadius:'50%' }}/> */}
                            </div>
                        </div>
                        <p className='ms-2 mb-0 purchase-book-write-name'>{purchasedEbook?.writter_name}</p>
                            
                    </div>
                </div>

            </div>
        </div>
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton style={{ border:'none' }} >
            </Modal.Header>
            <Modal.Body style={{ paddingBottom:'0px' }}>
                <div className='d-flex justify-content-center'>
                    <div>
                        <div className='d-flex justify-content-center mb-3'>
                            <img src={mobileIcon} alt="" />
                        </div>
                            <p className='mb-2 modal-content-title'>Use Mobile App</p>
                            <p className='mb-0 modal-content-details'>You need to use your Mojaru App to read this book.</p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer style={{ border:'none', justifyContent:'center' }}>
                <Button variant="secondary" onClick={handleClose} style={{ backgroundColor:'#532D80'}}>
                Ok, I Understand
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
};

export default BookCard;