import React from 'react';
import { Modal } from 'react-bootstrap';

const NotificationShowModal = (props) => {

    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props?.notificationdetails?.notification?.push_notification_subject}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex justify-content-center mb-3'>
                    {
                        props?.notificationdetails?.notification?.push_notification_image === null ? '' : <img src={`${props?.notificationdetails?.notification_image_path}${props?.notificationdetails?.notification?.push_notification_image}`} alt="" className='img-fluid' style={{ borderRadius:'12px' }} />
                    }
                </div>
                <p style={{ fontSize:'18px' }}>{props?.notificationdetails?.notification?.push_notification_message}</p>
            </Modal.Body>
        </Modal>
    );
};

export default NotificationShowModal;