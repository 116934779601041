import React, { useEffect, useState } from 'react';
import { dayDateMonth } from '../../../../../utilities/utilities';
import './ScheduleFreeClass.css';
import TimeSlot from './TimeSlot/TimeSlot';

const ScheduleFreeClass = () => {
    const [orientationCourseLists, setOrientationCourseLists] = useState([]);
    const [classSlots, setClassSlots] = useState({});
    const [selectedOption, setSelectedOption] = useState(null);
    const [classListOption, setClassListOption] = useState(null);
    const [selectedOptionStart, setSelectedOptionStart] = useState(true);

    useEffect(()=>{
        async function fetchOrientationCourseList(){
            let response = await fetch(`${process.env.REACT_APP_BASE_URL}api/orientation-course-lists`);
            response = await response.json();
            setOrientationCourseLists(response.data);
        }

        fetchOrientationCourseList()
    },[])


    useEffect(()=>{
        async function fetchOrientationCourseList(){
            let response = await fetch(`${process.env.REACT_APP_BASE_URL}api/get-orientation-class-slots?orientation_class_id=${selectedOption}`);
            response = await response.json();
            setClassSlots(response.data);
        }

        fetchOrientationCourseList()
    },[selectedOption])

    if(orientationCourseLists[0]?.id && selectedOptionStart){

        setSelectedOption(orientationCourseLists[0]?.id);
        setSelectedOptionStart(false);
        
    }

  const handleRadioChange = (event) => {
    setSelectedOption(parseInt(event.target.value));
    
  };
  const handelClassListChange = (event) => {
    setClassListOption(parseInt(event.target.value));
    
  };



    return (
        <section className='schedule-class-section'>
            <div className='container'>
                <p className='text-center pt-4 mb-0' style={{ fontSize: '24px', fontWeight:'500' }}>Schedule Your Orientation Class</p>
                <p className='text-center mb-5' style={{ color:'#f27321' , fontWeight:'500' }}>Select your preferable slot</p>


                
                <div className="orientation-course mb-5">
                    <p className='schedule-course-title'><i className="fas fa-graduation-cap me-1"></i>Choose your subject</p>
                    <div className="cf row mx-0 mb-2 gy-2">

                        {
                            orientationCourseLists?.map(orientationCourseList =>
                                <div className="col-lg-3" key={orientationCourseList?.id}>
                                    <div className="plan">
                                        <input
                                        type="radio"
                                        name={`class-schedule-${orientationCourseList?.id}`}
                                        id={`class-schedule-${orientationCourseList?.id}`}
                                        value={orientationCourseList?.id}
                                        checked={selectedOption === orientationCourseList?.id}
                                        onChange={handleRadioChange}
                                        />
                                        <label htmlFor={`class-schedule-${orientationCourseList?.id}`}>{orientationCourseList?.orientation_class_title} <br /> <span style={{ fontSize:'14px' }}>{`${orientationCourseList?.get_class_slots_count} slot available`}</span></label>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>

                <div className="orientation-course mb-5" id='classSlots-classList-section'>
                    <p className='schedule-course-title'><i className="fas fa-list me-1"></i>Select your class</p>
                    <div className="cf row mx-0 mb-3 gy-2">

                        {
                            classSlots?.class_lists?.map(class_list =>
                                <div className="col-lg-2" key={class_list?.class_id}>
                                    <div className="class-list">
                                        <input
                                        type="radio"
                                        name={`class-list-${class_list?.class_id}`}
                                        id={`class-list-${class_list?.class_id}`}
                                        value={class_list?.class_id}
                                        checked={classListOption === class_list?.class_id}
                                        onChange={handelClassListChange}
                                        />
                                        <label htmlFor={`class-list-${class_list?.class_id}`}>{class_list?.class_name}</label>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
                

                <div className="select-schedule-date mb-5" >
                    <p className='schedule-course-title ps-0'><i className="fas fa-calendar me-1"></i>Select a date</p>
                    <ul className="row nav nav-pills mb-3 mt-3" id="pills-tab" role="tablist">
                        {
                            classSlots?.dates?.map((date, index) =>
                                <li className="col-lg-1 nav-item py-1" role="presentation" key={index}>
                                    <button className={`nav-link schedule-date category-name w-100 test py-1 ${date?.orientation_class_date} ${classSlots?.dates[0]?.orientation_class_date} ${date?.orientation_class_date ===  classSlots?.dates[0]?.orientation_class_date ? "active" : ""}`} 
                                    id={`pills-category_${date?.orientation_class_date}-tab`} data-bs-toggle="pill" data-bs-target={`#pills-category_${date?.orientation_class_date }`} 
                                    type="button" role="tab" aria-controls={`pills-category_${date?.orientation_class_date }`} aria-selected="true">
                                        <span >{dayDateMonth(date?.orientation_class_date).day}</span> <br />
                                        <span style={{ fontSize:'30px' }}>{dayDateMonth(date?.orientation_class_date).date}</span> <br />
                                        <span>{dayDateMonth(date?.orientation_class_date).month}</span>
                                    
                                    </button>
                                </li>
                                
                            )
                        }
                    </ul>

                    <p className='schedule-course-title ps-0'><i className="fas fa-clock me-1"></i>Select your class</p>
                    <div className="tab-content mt-2 mb-5 course_web_view" id="pills-tabContent">
                        {
                            classSlots?.dates?.map((date, index) =>{
                                return(
                                <div className={`tab-pane fade ${date?.orientation_class_date} ${classSlots?.dates[0]?.orientation_class_date} ${date?.orientation_class_date ===  classSlots?.dates[0]?.orientation_class_date ? "active show" : ""}`} id={`pills-category_${date?.orientation_class_date}`} role="tabpanel" aria-labelledby={`pills-category_${date?.orientation_class_date}-tab`} key={date?.orientation_class_date}>
                                    <div className="time-slot mb-5">
                                        
                                            <div className="cf row mx-0 mb-3 gy-2">
                                                {
                                                    (date?.slots?.map((slot, index) =>{
                                                        if(slot?.orientation_class_date === date?.orientation_class_date) {
                                                            return <TimeSlot slot={slot} slots={date?.slots}/> 
                                                        }
                                                        return null;
                                                    }))
                                                }
                                            </div>
                                        </div>
                                </div>
                                )
                            })
                        }
                    </div>

                </div> 

                

                

            </div>

        </section>
    );
};

export default ScheduleFreeClass;