import React, { useEffect, useState } from "react";
import PageTitle from "../../../shared/PageTitle/PageTitle";
import Blog from "./Blog/Blog";
import './Blogs.css';
import Loader from "../../../shared/Loader/Loader";
import FeaturedBlogs from "./FeaturedBlogs/FeaturedBlogs";

const Blogs = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [blogs, setBlogs] = useState({});
    /* const [mySwiper, setMySwiper] = useState(null); */
    /* useEffect(() => {
        const timer = setTimeout(() => {
            setISLoading(false)
        }, 2000);
      }, []);
 */

      useEffect(()=>{
            async function fetchBlog(){
                let data = await fetch(`${process.env.REACT_APP_BASE_URL}api/blogs`);
                data = await data.json();
                setBlogs(data.data);
                setIsLoading(false);
            }
        fetchBlog();
      },[])



    if(isLoading){
        return (<Loader/>);
    }

    const handelGetBlogCategory = (event) =>{
        const search_info ={
            search_data: event.target.value
        } 
        fetch(`${process.env.REACT_APP_BASE_URL}api/filter-blogs`,{
            method: 'POST',
            headers:{
                'content-type' : 'application/json',
                'accept' : 'application/json',
            },
            body: JSON.stringify(search_info),
        })
        .then(res => res.json())
        .then(data => setBlogs(data.data))
    }

    


    const blog_image_path = blogs?.blog_image_path;



    return (
        <div>
        <PageTitle title="Blogs"></PageTitle>
            <div className="borderTop" style={{ paddingBottom: "21rem" }}>
                <div className="container">
                <div className="position-sticky" style={{ top:'7.7rem', height:'7rem', zIndex:'999', backgroundColor:'#ffffff' }}>
                    <div className="d-flex justify-content-between align-items-center pt-5" >
                        <div style={{ width:'30%' }}>
                            <select className="form-select form-select-lg" style={{ fontSize:'18px' }} onChange={handelGetBlogCategory}>
                                <option value=''>ক্যাটেগরি সিলেক্ট করুন</option>
                                {
                                    blogs?.blog_categories?.map(blog_category => <option key={blog_category?.blog_category_id} name='search_data' value={blog_category?.blog_category_id}>{blog_category?.blog_category}</option>)
                                }
                            </select>
                        </div>
                        <div>
                            <div className="input-group rounded pe-lg-4" onChange={handelGetBlogCategory}>
                                <input type="search" name="search_data" className="form-control rounded" placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
                                <span className="input-group-text border-0" id="search-addon">
                                    <i className="fas fa-search"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-lg-4 mt-2 gy-lg-0 gy-3">
                    <div className="col-lg-5 featured-blog">
                    {/* <section className="position-sticky" style={{ top:'16rem' }}>

                        <div className="swiper mySwiper">
                            <div className="swiper-wrapper">
                                {
                                    blogs?.featured_blogs?.map(featured_blog => <FeaturedBlog key={featured_blog.id} featured_blog={featured_blog} blog_image_path={blog_image_path}></FeaturedBlog>)
                                }
                            </div>
                            <div className="ps-5 pb-3 swiper-pagination text-white text-start"></div>
                        </div>
                    </section> */}
                    <FeaturedBlogs blogs={blogs} blog_image_path={blog_image_path} />

                    </div>
                    <div className="col-lg-7" style={{ top: '4rem' }}>
                        <div className="row gy-3">
                            {
                                blogs?.blogs?.map(blog => <Blog key={blog.id} blog={blog} blog_image_path={blog_image_path}></Blog>)
                            }
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
};

export default Blogs;
