import React, { useState, useEffect } from 'react';
import TopCircle from '../../../../../../images/top_circle.png';
import BottomCircle from '../../../../../../images/bottom_circle.png';
import CircleIcon from "../../../../../../images/arrow_white.png";
import './CourseEnroll.css';
import {englishToBengaliNumberFormat} from '../../../../../../utilities/utilities';
import { Link } from 'react-router-dom';
import {encode as base64_encode} from 'base-64';
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";

const CourseEnroll = ({courseDetail, isCoursePurchase, id, affiliate_id}) => {
    let encoded = base64_encode(`${id}`);
    const [modalShow, setModalShow] = useState(false);
    const handleClose = () => setModalShow(false);
    const handleShow = () => setModalShow(true);
    const [value, setValue] = useState("");
    const [districts, setDistricts] = useState([]);

    const handleChange = (event) => {
        const result = event.target.value.replace(/\D/g, "");
        setValue(result);
    };

    useEffect(() => {
        async function fetchDistricts() {
            let response = await fetch(
                `${process.env.REACT_APP_BASE_URL}api/districts`
            );
            response = await response.json();
            setDistricts(response.data.districts);
        }
        fetchDistricts();
    }, []);

    /* Form submit */
    const handelFreeClassForm = (event) => {
        event.preventDefault();

        const student_formInfo = {
            student_name: event.target.name.value,
            institution_name: event.target.school_name.value,
            student_age: event.target.age.value,
            contact_number: event.target.contact_number.value,
            student_email: event.target.email.value,
            district: event.target.district.value,
            comment: event.target.opinion.value,
            course_id: id,
        };

        fetch(`${process.env.REACT_APP_BASE_URL}api/free-course-registration`,{
            method: 'POST',
            headers:{
                'content-type': 'application/json',
            },
            body: JSON.stringify(student_formInfo)
        })
        .then(res => res.json())
        .then(data =>{
            if(data.message_type === 'success'){
                toast.success(data.message);
                event.target.reset();
                setModalShow(false);
            }else{
                toast.error(data.message);
            }
        })
    };

    return (
        <div>
            <div  className="position-relative" >
            
                
                    <div className="course-enroll position-relative">
                        <div className="position-absolute top-0 end-0">
                            <img src={TopCircle} alt=""/>
                        </div>
                        <div className='align-items-center justify-content-around mb-3'>        
                            {
                                (courseDetail?.course_initial_price === '0' && courseDetail?.course_current_price === '0') ? <p className="mb-0 text-center" style={{ color: '#ffffff', fontSize:'36px', fontWeight:'600'}}>ফ্রি কোর্স</p> 
                                : (courseDetail?.course_initial_price !== '0' && courseDetail?.course_current_price !== '0'&& courseDetail?.course_initial_price === courseDetail?.course_current_price) ? <p className="balance_symbol text-center" style={{ fontSize: '36px', color:'#ffffff' }}>৳<span>{englishToBengaliNumberFormat(courseDetail?.course_initial_price)}</span></p>
                                : <><p className='price text-white mb-0 text-center'><del className='balooda-medium'>৳{englishToBengaliNumberFormat(courseDetail?.course_initial_price)}</del></p>
                                    <p className='offer-price text-white mb-0 text-center'><span className='balooda-medium'>৳ </span><span className='balooda-medium'>{englishToBengaliNumberFormat(courseDetail?.course_current_price)}</span></p></>
                            }
                        </div>

                        <div className='buy-course'>
                            {
                                isCoursePurchase ? <p className='mb-0'>ইতিমধ্যে ক্রয়কৃত</p> : 
                                affiliate_id ? <Link to={`/latest-courses?course_id=${encoded}&aff=${affiliate_id}`}>কোর্সটি কিনুন</Link> :
                                <Link to={`/latest-courses?course_id=${encoded}`}>কোর্সটি কিনুন</Link>
                            }
                        </div>
                            {
                                !isCoursePurchase && 
                                <div className='mt-1 free-course-registration'>
                                    {/* <Link to={`/free-course-registration/${id}`} >ফ্রি ক্লাসের জন্য রেজিস্ট্রেশন</Link> */}
                                    <p onClick={handleShow} style={{ cursor:'pointer' }} className='py-2'>ফ্রি ক্লাসের জন্য রেজিস্ট্রেশন</p>
                                </div>
                            }
                        
                            <p className='text-center for-help font-16'>সাহায্যের জন্য: {courseDetail?.help_line}</p>
                            {
                                courseDetail?.course_video_related_info && 
                                    <div className='d-flex align-items-center course-duration'>
                                        <img src={CircleIcon} alt="" height={20} width={20}/>
                                        <p className='mb-0 text-white ps-3'>{courseDetail?.course_video_related_info}</p>
                                    </div>
                            }
                            {
                                courseDetail?.course_access_related_info && 
                                    <div className='d-flex align-items-center course-duration'>
                                        <img src={CircleIcon} alt="" height={20} width={20}/>
                                        <p className='mb-0 text-white ps-3'>{courseDetail?.course_access_related_info}</p>
                                    </div>
                            }
                            {
                                courseDetail?.course_certificate_related_info && 
                                    <div className='d-flex align-items-center course-duration'>
                                        <img src={CircleIcon} alt="" height={20} width={20}/>
                                        <p className='mb-0 text-white ps-3'>{courseDetail?.course_certificate_related_info}</p>
                                    </div>
                            }

                            {
                                !courseDetail?.course_video_related_info && !courseDetail?.course_access_related_info && !courseDetail?.course_certificate_related_info &&
                                    <>
                                        <br />
                                        <br />
                                        <br />
                                    </>
                            }

                        {/* <div className='d-flex align-items-center course-duration'>
                            <img src={CircleIcon} alt="" height={20} width={20}/>
                            <p className='mb-0 text-white ps-3'>{courseDetail?.course_access_related_info}</p>
                        </div>

                        <div className='d-flex align-items-center course-duration'>
                            <img src={CircleIcon} alt="" height={20} width={20}/>
                            <p className='mb-0 text-white ps-3'>{courseDetail?.course_certificate_related_info}</p>
                        </div> */}
                        <div className="position-absolute bottom-0 start-0">
                            <img src={BottomCircle} alt=""/>
                        </div>
                </div>
            </div>


            {/* Student Profile modal  */}
          <Modal show={modalShow} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                কিছু তথ্য
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handelFreeClassForm}>
                  <div className="row">
                    <div className="col-lg-12">
                      <p className='mb-2 font-400'>শিক্ষার্থীর নাম<span className='text-danger'>*</span></p>
                      <input className="mb-3 form-control form-control-lg student-profile-info" name="name" type="text" placeholder="Write Your Full Name" required/>
                    </div>

                    <div className="col-lg-12">
                      <p className='mb-2 font-400'>শিক্ষা প্রতিষ্ঠানের নাম <span className='text-danger'>*</span></p>
                      <input className="mb-3 form-control form-control-lg student-profile-info" name="school_name" type="text" placeholder="Write Your School Name" required/>
                    </div>
                    <div className="col-lg-12">
                      <p className='mb-2 font-400'>শিক্ষার্থীর বয়স<span className='text-danger'>*</span></p>
                      <input className="mb-3 form-control form-control-lg student-profile-info" name="age" type="text" placeholder="Write Your Age" required/>
                    </div>
                    <div className="col-lg-12">
                      <p className='mb-2 font-400'>যোগাযোগের নম্বর <span className='text-danger'>*</span></p>
                      <input className="mb-3 form-control form-control-lg student-profile-info" name="contact_number" type="text" placeholder="Write Your Contact Number" value={value}
                        onChange={handleChange} required/>
                    </div>
                    <div className="col-lg-12">
                      <p className='mb-2 font-400'>ই-মেইল <span className='text-danger'>*</span></p>
                      <input className="mb-3 form-control form-control-lg student-profile-info" name="email" type="text" placeholder="Write Your Email" required/>
                    </div>
                    <div className="col-lg-12">
                      <p className='mb-2 font-400'>জেলা <span className='text-danger'>*</span></p>
                        <select name='district' className=" mb-3 form-select form-control form-control-lg student-profile-info" id="select_box">
                            <option value="" required>Select District</option>
                            {districts.map((district) => (
                                <option key={district.district_id} value={`${district.district_en_name} - ${district.district_bn_name}`}>
                                    {district.district_en_name} - {district.district_bn_name}
                                </option>
                            ))}
                            
                        </select>
                    </div>

                    <div className="col-lg-12">
                      <p className='mb-2 font-400'>মন্তব্য <span className='text-danger'>*</span></p>
                        <textarea className="mb-3 form-control form-control-lg" name="opinion"  rows="3" required></textarea>
                    </div>

                  </div>

                  <div className=" d-flex justify-content-end">
                  <input className="btn text-white mt-4 py-2" style={{ backgroundColor:'#532D80', fontSize:'22' }} type="submit" value='সাবমিট করুন'></input>
                  </div>
                </form>
              
            </Modal.Body>
            
          </Modal>
        </div>
    );
};

export default CourseEnroll;