import React, { useEffect, useState } from "react";
import CourseCard from "./CourseCard/CourseCard";
import {englishToBengaliNumberFormat} from "../../../../utilities/utilities";
import "./MyCourse.css";
import Skeleton from 'react-loading-skeleton';
import StudentPageTitle from "../../shared/StudentPageTitle/StudentPageTitle";

const MyCourse = () => {
    const [purchasedCourses, setPurchasedCourses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}purchased-course-lists?user_id=${localStorage.getItem("user_id")}`,{
                    method:'GET',
                    headers:{
                        'content-type' : 'application/json',
                        'accept' : 'application/json',
                        'Authorization' : `Bearer ${localStorage.getItem("auth_token")}`,
                        'SecretKey' : `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
                    },
                })
            .then((res) => res.json())
            .then((data) => {
                setPurchasedCourses(data.data);
                setIsLoading(false);
            });
    }, []);

    return (
        <div className="main-content">
            <StudentPageTitle title="My Course"></StudentPageTitle>
            <div className="page-content p-0">
                <div className="container-fluid">
                    <div className="my-course-section">
                        {/* New Design */}
                        {
                            isLoading && <>
                                <Skeleton className='mb-1' height={50} width={165}/>
                                <Skeleton className='mb-1' height={20} width={250}/>
                                <div className="row g-3">
                                    <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                        <Skeleton style={{ width:'100%', height:'450px', borderRadius:'1rem' }}/>
                                    </div>
                                    <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                        <Skeleton style={{ width:'100%', height:'450px', borderRadius:'1rem' }}/>
                                    </div>
                                    <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                        <Skeleton style={{ width:'100%', height:'450px', borderRadius:'1rem' }}/>
                                    </div>
                                    <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                        <Skeleton style={{ width:'100%', height:'450px', borderRadius:'1rem' }}/>
                                    </div>
                                    <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                        <Skeleton style={{ width:'100%', height:'450px', borderRadius:'1rem' }}/>
                                    </div>
                                    <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                        <Skeleton style={{ width:'100%', height:'450px', borderRadius:'1rem' }}/>
                                    </div>
                                    <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                        <Skeleton style={{ width:'100%', height:'450px', borderRadius:'1rem' }}/>
                                    </div>
                                    <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                                        <Skeleton style={{ width:'100%', height:'450px', borderRadius:'1rem' }}/>
                                    </div>
                                </div>
                            </>
                        }
                        <p className="my-course-title mb-1">সকল কোর্স</p>
                        {
                            purchasedCourses?.purchased_courses?.length > 0 &&
                                <p style={{ fontWeight: "400" }}>
                                    সর্বমোট  {englishToBengaliNumberFormat( purchasedCourses?.purchased_courses?.length)} টি কোর্সে অ্যাসাইন করা হয়েছে
                                </p>
                        }

                        <div className="row g-3">
                        {
                            purchasedCourses?.purchased_courses?.length > 0 ?
                            purchasedCourses?.purchased_courses?.map(
                                (purchasedCourse) => (
                                    <CourseCard
                                        key={purchasedCourse.course_id}
                                        purchasedCourse={purchasedCourse}
                                        course_img_pathLink={
                                            purchasedCourses?.course_image_path
                                        }
                                        teacher_img_pathLink={
                                            purchasedCourses?.teacher_image_path
                                        }
                                    />
                                )
                            )
                            :
                            <p className="text-center" style={{ fontWeight: "400", fontSize:'22px' }}>কোর্স এখনও ক্রয় করা হয়নি</p>
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyCourse;
