/* import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CourseShortInformation from "../../shared/CourseDetails/CourseShortInformation/CourseShortInformation";
import NoVideo from "../../../../images/default_video_banner.jpg";
import "./FreeClassRegistration.css";
import PageTitle from "../../shared/PageTitle/PageTitle"; */

const FreeClassRegistration = () => {
   /*  const [courseDetail, setCourseDetail] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const freeClassRegistration = true;
    const { id } = useParams();
    const [value, setValue] = useState("");

    useEffect(() => {
        async function fetchMyAPI() {
            let response = await fetch(
                `${process.env.REACT_APP_BASE_URL}api/course-detail?course_id=${id}`
            );
            response = await response.json();
            setCourseDetail(response.data);
            setIsLoading(false);
        }
        fetchMyAPI();

        async function fetchDistricts() {
            let response = await fetch(
                `${process.env.REACT_APP_BASE_URL}api/districts`
            );
            response = await response.json();
            setDistricts(response.data.districts);
            setIsLoading(false);
        }
        fetchDistricts();
    }, []);

    const handleChange = (event) => {
        const result = event.target.value.replace(/\D/g, "");
        setValue(result);
    };

    const handelFreeClassForm = (event) => {
        event.preventDefault();

        const student_formInfo = {
            student_name: event.target.name.value,
            institution_name: event.target.school_name.value,
            student_age: event.target.age.value,
            contact_number: event.target.contact_number.value,
            student_email: event.target.email.value,
            district: event.target.district.value,
            comment: event.target.opinion.value,
            course_id: id,
        };
        console.log(student_formInfo);

        /* fetch(`${process.env.REACT_APP_BASE_URL}api/free-course-registration`,{
            method: 'POST',
            headers:{
                'content-type': 'application/json',
            },
            body: JSON.stringify(student_formInfo)
        })
        .then(res => res.json())
        .then(data =>{
            if(data.message_type === 'success'){
                toast.success(data.message);
                event.target.reset();
            }else{
                toast.error(data.message);
            }
        }) 
    };

    return (
        <div>
            <PageTitle title="Free Class Form"></PageTitle>
            <>
                <div className="borderTop" style={{ paddingBottom: "23rem" }}>
                    <div className="container">
                        <div className="row mx-0 gy-3 course-detail-top">
                            <div className="col-lg-9">
                                <p className="update-date">
                                    সর্বশেষ আপডেট হয়েছে: 21 ডিসেম্বর 2021
                                </p>
                                <p className="course-name">
                                    {courseDetail?.course_detail?.course_name}
                                </p>

                                {courseDetail?.course_detail
                                    ?.course_introduction_video_link !==
                                    undefined &&
                                courseDetail?.course_detail
                                    ?.course_introduction_video_link !==
                                    null ? (
                                    <iframe
                                        className="course-video"
                                        src={`https://www.youtube.com/embed/${courseDetail?.course_detail?.course_introduction_video_link}`}
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                ) : (
                                    <img
                                        src={NoVideo}
                                        alt=""
                                        className="my-4"
                                        style={{ width: "100%" }}
                                    />
                                )}

                                {/* form 
                                <div className="free-class-form-section">
                                    <form onSubmit={handelFreeClassForm}>
                                        <input
                                            type="text"
                                            className="form-control free-class-inputForm input-height"
                                            name="name"
                                            id="exampleFormControlInput1"
                                            placeholder="শিক্ষার্থীর নাম *"
                                        />
                                        <input
                                            type="text"
                                            className="form-control free-class-inputForm input-height"
                                            name="school_name"
                                            id="exampleFormControlInput1"
                                            placeholder="শিক্ষা প্রতিষ্ঠানের নাম"
                                        />
                                        <input
                                            type="number"
                                            className="form-control free-class-inputForm input-height"
                                            name="age"
                                            id="exampleFormControlInput1"
                                            placeholder="শিক্ষার্থীর বয়স"
                                        />
                                        <input
                                            type="text"
                                            className="form-control free-class-inputForm input-height"
                                            name="contact_number"
                                            id="exampleFormControlInput1"
                                            placeholder="যোগাযোগের নম্বর *"
                                            value={value}
                                            onChange={handleChange}
                                        />
                                        <input
                                            type="email"
                                            className="form-control free-class-inputForm input-height"
                                            name="email"
                                            id="exampleFormControlInput1"
                                            placeholder="ই-মেইল"
                                        />
                                        <select
                                            className="form-select form-select-lg free-class-inputForm form-control input-height"
                                            name="district"
                                            aria-label=".form-select-lg example"
                                        >
                                            <option className="form-control">
                                                জেলা *
                                            </option>
                                            {districts.map((district) => (
                                                <option key={district.district_id} value={`${district.district_en_name} - ${district.district_bn_name}`}>
                                                    {district.district_en_name} - {district.district_bn_name}
                                                </option>
                                            ))}
                                        </select>

                                        <textarea
                                            className="form-control free-class-inputForm"
                                            name="opinion"
                                            id="exampleFormControlTextarea1"
                                            rows="4"
                                            placeholder="মন্তব্য"
                                        ></textarea>

                                        <div className="d-flex justify-content-center mt-4">
                                            <input
                                                type="submit"
                                                value="সাবমিট করুন"
                                                className="btn-freeClassFormSubmit px-5 py-2"
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-3 ">
                                <CourseShortInformation
                                    freeClassRegistration={
                                        freeClassRegistration
                                    }
                                    course_features={
                                        courseDetail?.course_features
                                    }
                                    course_teachers={
                                        courseDetail?.course_teachers
                                    }
                                    teacher_profile_path={
                                        courseDetail?.teacher_profile_picture_path
                                    }
                                ></CourseShortInformation>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </div>
    ); */
};

export default FreeClassRegistration;
