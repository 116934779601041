import React,{ useEffect, useState, useContext }from 'react';
import { englishToBengaliNumberFormat } from '../../../../../utilities/utilities';

import allAssignment from '../../../../../images/all-assignment.svg';
import { LMSContext } from '../LMS';
import PracticeTest from './PracticeTest/PracticeTest';

const PracticeTests = () => {
    const[assignments, setAssignments] = useState([]);
    const {batch_id, course_id} = useContext(LMSContext);
    useEffect(()=>{
        fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}assignments?course_id=${course_id}&batch_id=${batch_id}&user_id=${localStorage.getItem("user_id")}`,{
                    method:'GET',
                    headers:{
                        'content-type' : 'application/json',
                        'accept' : 'application/json',
                        'Authorization' : `Bearer ${localStorage.getItem("auth_token")}`,
                        'SecretKey' : `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
                    },
                })
            .then((res) => res.json())
            .then((data) => setAssignments(data.data));
    },[course_id, batch_id]);

    
    
    return (

        <div className="row gx-5 mx-0">


            <div className=' col-lg-3 lms-subject-section'>
                <div className="nav flex-column nav-pills assignment-subject p-2 bg-white custom-card-shadow mb-4" id="v-pills-tab" role="tablist" aria-orientation="vertical" style={{ borderRadius:'16px' }}>
                    <button className="mb-2 nav-link text-dark active" id="v-pills-home-practice-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home-practice" type="button" role="tab" aria-controls="v-pills-home-practice" aria-selected="true">
                    <img src={allAssignment} alt="" width={48} height={48} className='me-2' style={{ borderRadius: '15px' }}/>
                    সকল বিষয়
                    </button>
                    {
                        assignments?.course_subjects?.map(course_subject => 
                            <button key={course_subject?.course_subject_id} className="nav-link text-dark mb-2" id={`v-pills-practice-subject_${course_subject?.course_subject_id}-tab`} data-bs-toggle="pill" data-bs-target={`#v-pills-practice-subject_${course_subject?.course_subject_id}`} type="button" role="tab" aria-controls={`v-pills-practice-subject_${course_subject?.course_subject_id}`} aria-selected="false">
                                <img src={`${assignments?.subject_image_path}${course_subject?.subject_image}`} alt="" width={48} height={48} className='me-2' style={{ borderRadius: '8px' }}/>
                                {course_subject?.subject_name}
                            </button>

                        )
                    }
                    
                </div>
            </div>
            <div className="col-lg-9 px-0 mx-0 tab-content bg-white custom-card-shadow all-assignment-section" id="v-pills-tabContent">
        {/* <div className='bg-white custom-card-shadow all-assignment-section'> */}
                <div className="tab-pane fade show active" id="v-pills-home-practice" role="tabpanel" aria-labelledby="v-pills-home-practice-tab">
                    
                        <p className='course-total-assignment mb-0'>{englishToBengaliNumberFormat(assignments?.assignments?.length)} টি প্র্যাকটিস টেস্ট</p>
                        <div className='total-assignment-divider'></div>

                            <div className='row mt-4 p-3 g-5 mx-0'>
                            {   assignments?.length === 0 ? <p className="d-flex justify-content-center mb-0 w-100" style={{ fontSize: "22px", fontWeight: "600" }}>
                                তোমাকে এখনো কোন অ্যাসাইন্মেন্ট দেওয়া হয়নি </p> :
                                assignments?.assignments?.map(assignment => <PracticeTest key={assignment.id} assignment={assignment}/> )
                            }
                        </div>
                        <p className='text-center mb-4' style={{ color: '#BDBCBE' }}>আপনি তালিকার শেষে পৌঁছে গিয়েছেন</p>
                    
                </div>
                
                {
                    assignments?.course_subjects?.map(course_subject => {
                        return(
                            <div key={course_subject?.course_subject_id} className="tab-pane fade" id={`v-pills-practice-subject_${course_subject?.course_subject_id}`} role="tabpanel" aria-labelledby={`v-pills-practice-subject_${course_subject?.course_subject_id}-tab`}>
                            <p className='course-total-assignment mb-0'>{course_subject?.subject_name} প্র্যাকটিস টেস্ট</p>
                            <div className='total-assignment-divider'></div>
                            <div className='row mt-4 p-3 g-5 mx-0'>
                            {
                                assignments?.assignments?.map(assignment => {
                                    if(course_subject?.course_subject_id === assignment?.course_subject_id) {
                                        return <PracticeTest key={assignment.id} assignment={assignment}/>  
                                      }
                                      return null;
                                    
                                } )
                            }
                            </div>
                            <p className='text-center mb-4' style={{ color: '#BDBCBE' }}>আপনি তালিকার শেষে পৌঁছে গিয়েছেন</p>
                        </div>
                        )
                    }
                        
                    )
                }
            
            </div> 
        </div> 
   
        
    );
};

export default PracticeTests;