import React from 'react';
import { Link } from 'react-router-dom';
import BlogPath from '../../../../../../../images/blog_path.png';
import defaultBlogImage from '../../../../../../../images/default-blog-thumb.png';

const FeaturedBlog = ({featured_blog, blog_image_path}) => {

    const today = new Date(featured_blog?.created_at);
        const month = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
        const date = today.getDate() + ' ' + month[today.getMonth()] + ', ' + today.getFullYear();

        
    return (
        <div className="swiper-slide">
            <Link to="/blog-details" state={{ 
                blog_title: featured_blog?.blog_title,
                blog_details: featured_blog?.blog_long_desc,
                img_path: blog_image_path,
                blog_img: featured_blog?.blog_image
             }}>

                <div className="blog-left">
                    {/* <img src={`${blog_image_path}${featured_blog?.blog_image}`} className="img-fluid"/> */}
                    {(featured_blog?.blog_image !== undefined && featured_blog?.blog_image !== null) ? 
                            <img src={`${blog_image_path}${featured_blog?.blog_image}`} alt="" className="img-fluid" />
                            : 
                            <img src={defaultBlogImage} alt="" className="img-fluid"/>
                        }
                    <div className="inside-left-blog ps-5">
                        <div className='position-relative'>
                            <img src={BlogPath} alt=""  style={{ height: '40px', width: '142px'}}/>
                            <p className="mb-1 inside-left-blog blog-date ps-4 ">{featured_blog?.blog_category}</p>
                        </div>
                        <p className="text-white left-blog-title mb-1">{featured_blog?.blog_title}</p>
                        <p className="font-16 text-white mb-1">{date}</p>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default FeaturedBlog;