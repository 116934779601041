import React, { useState, useEffect, useContext } from "react";
import { dateConverterDay, dateConverterEnglish } from "../../../../../utilities/utilities";
import { LMSContext } from '../LMS';
import './Attendance.css';
const Attendance = () => {
    const [attendanceList, setAttendanceList] = useState([]);
    const {batch_id, course_id} = useContext(LMSContext);
    useEffect(()=>{
        fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}live-class-attendance-list?course_id=${course_id}&batch_id=${batch_id}&user_id=${localStorage.getItem("user_id")}`,{
                    method:'GET',
                    headers:{
                        'content-type' : 'application/json',
                        'accept' : 'application/json',
                        'Authorization' : `Bearer ${localStorage.getItem("auth_token")}`,
                        'SecretKey' : `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
                    },
                })
            .then((res) => res.json())
            .then((data) => setAttendanceList(data.data));
    },[course_id, batch_id]);
    return (
        <div className="bg-white custom-card-shadow" style={{ borderRadius: "16px" }}>
            <div className="d-flex justify-content-between align-items-center px-4">
                <p className="course-total-assignment mb-0 ps-0">My Attendance</p>
                {/* <select className="form-select" style={{ width:'185px', backgroundColor:'#F4F4F4' }}>
                    <option value='all'>সব দিনের উপস্থিতি</option>
                    <option value="7">গত ৭ দিনের উপস্থিতি</option>
                    <option value="15">গত ১৫ দিনের উপস্থিতি</option>
                    <option value="30">গত ৩০ দিনের উপস্থিতি</option>
                </select> */}
            </div>
            <div className="total-assignment-divider"></div>

            <div className="attendance-section px-4 pb-4">
                <div className='result-offcanvas-section mb-4 pb-3'>
                    <div className='d-flex justify-content-around align-items-center'>
                        <div>
                            <p className='mb-1 attend-class-summary'>Total Class</p>
                            <p className='mb-0 total-class-summary text-center'>{attendanceList?.total_class < 10 ? `0${attendanceList?.total_class}` : attendanceList?.total_class}</p>
                        </div>
                        <div>
                            <p className='mb-1 attend-class-summary'>Present</p>
                            <p className='mb-0 total-present-summary text-center'>{attendanceList?.present < 10 ? `0${attendanceList?.present}` : attendanceList?.present}</p>
                        </div>
                        <div>
                            <p className='mb-1 attend-class-summary'>Absent</p>
                            <p className='mb-0 total-absent-summary text-center' style={{ color:'#D0202F' }}>{attendanceList?.absent < 10 ? `0${attendanceList?.absent}` : attendanceList?.absent}</p>
                        </div>
                    </div>
                </div>

                <div className='attendence_list_section'>
                    <div className='attendence_list' >

                    {
                        attendanceList?.attendance?.map((list, index) => 
                            <div key={index}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <p className="atn-date mb-1">{dateConverterEnglish(list?.live_class_date)}</p>
                                        <p className="atn-day mb-1">{dateConverterDay(list?.live_class_date)}</p>
                                    </div>
                                    <div>
                                        <p className="atn-date mb-1">{list?.course_name}</p>
                                        <p className="atn-day mb-1">{list?.subject_name}</p>
                                    </div>
                                    <div>
                                        <p className={`mb-0 ${list?.present_or_absent ? 'attendance-present' : 'attendance-absent'}`}>{list?.present_or_absent ? 'Present' : 'Absent'}</p>
                                    </div>
                                </div>
                                <div className='divider-border my-3' style={attendanceList?.attendance?.length === index+1 ? { display: "none" } : { display: "block" }}></div>
                            </div>
                        )
                    }

                    
                    

                </div>
                </div>

               
            </div>
        </div>
    );
};

export default Attendance;
