import React from 'react';
import StudentPageTitle from '../../../../../shared/StudentPageTitle/StudentPageTitle';
import unionIcon from '../../../../../../../images/book-half.svg';
import noPerson from '../../../../../../../images/blank.svg';
import linkIcon from '../../../../../../../images/link-icon.svg';
import pdfIcon from '../../../../../../../images/pdf-icon.svg';
import './LectureView.css';
import { Link, useLocation } from 'react-router-dom';
import { useState,useEffect } from 'react';
import { dateConverter } from '../../../../../../../utilities/utilities';

const LectureView = () => {
    let location = useLocation();
    let lecture_id = location?.state?.lecture_id;
    let lesion_name = location?.state?.lesion_name;
    let topic_name = location?.state?.topic_name;
    let course_id = location?.state?.course_id;
    let batch_id = location?.state?.batch_id;
    const [lectureDetail, setLectureDetails] = useState({});
    useEffect(()=>{
        fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}get-video-lecture?lecture_id=${lecture_id}`)
            .then((res) => res.json())
            .then((data) => setLectureDetails(data?.data));
        /* async function fetchLectureViewInfo(){
            let response = await fetch(
                `${process.env.REACT_APP_BASE_URL_STUDENT}get-video-lecture?lecture_id=${lecture_id}`
            );
            response = await response.json();
            setLectureDetails(response?.data);
        }

        fetchLectureViewInfo() */
    },[lecture_id]);


    const search = location.search;
    const class_content = new URLSearchParams(search).get("class_content");


    return (
        <div className="main-content">
                <StudentPageTitle title="View Lecture"></StudentPageTitle>
                <div className="page-content p-0">
                    <div className="container-fluid">
                        <div className="my-course-section">
                        <p className='mb-4'><Link to='/my-course/lms' state={{ course_id: course_id, batch_id: batch_id, class_content: 'lecture' }} style={{ color:'#532D80', fontWeight:'400', textDecoration:'none' }}> কোর্সের বিস্তারিত  / </Link><span style={{ color:'#918F92', fontWeight:'400' }}> লেকচারের বিস্তারিত</span></p>
                        <div className="bg-white custom-card-shadow all-assignment-section" >
                            <div className='d-flex justify-content-between align-items-center'>
                                <p className='course-total-assignment mb-0'>লেকচারের বিস্তারিত</p>
                                {/* <p className='course-total-assignment mb-0 pe-4'>লেকচারের বিস্তারিত</p> */}

                                {
                                    class_content &&
                                    <div className='pe-4'>
                                        <Link to='/my-course/lms'
                                        state={{ course_id: course_id, batch_id: batch_id, class_content: 'content_list' }} className='back-btn text-center'><i className="fa-solid fa-arrow-left-long pe-2"></i>Back to Class List</Link>
                                    </div>
                                }
                            </div>
                            <div className='total-assignment-divider'></div>
                            <div className='lecture-section p-4'>
                                <div>
                                    {/* <iframe className='lecture-video-section' src="https://www.youtube.com/embed/eI4an8aSsgw" allowFullScreen></iframe> */}
                                    {
                                        lectureDetail?.video_lecture?.youtube_or_vimeo === 2 ? <iframe className='lecture-video-section' src={`https://player.vimeo.com/video/${lectureDetail?.video_lecture?.video_id}?h=83d5533b4c&badge=0&autopause=0&player_id=0&app_id=58479/embed`} title="vimeo" allow="autoplay; fullscreen; picture-in-picture"></iframe> :
                                        lectureDetail?.video_lecture?.youtube_or_vimeo === 1 ? <iframe className='lecture-video-section' src={`https://www.youtube.com/embed/${lectureDetail?.video_lecture?.video_id}?controls=1&autoplay=1`} title="youtube" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> : 
                                        ''
                                    }
                                </div>
                                <div className='d-flex align-items-center mb-3 mt-3'>
                                    <div className='d-flex align-items-center'>
                                        <img src={unionIcon} alt="" width={15} height={15}/>
                                        <p className='course-total-student ms-2 mb-0' style={{ fontSize:'16px' }}>{lectureDetail?.video_lecture?.course_subject_name}</p>
                                    </div>
                                    {/* <div className='d-flex align-items-center'>
                                        <div className='course-card-student-pointer ms-4'></div>
                                        <p className='course-total-student mb-0 ms-2' style={{ fontSize:'16px' }}>কোষ বিভাজন</p>
                                    </div> */}
                                </div>
                                {
                                    lesion_name && <h2 className='lecture-lecture-name mb-2'>{lesion_name}</h2> 
                                }
                                {
                                    topic_name && <p className='lecture-topic-name mb-3'>{topic_name}</p>
                                }
                                
                                

                                <div className='d-flex align-items-center w-100 mb-3'>
                                    {lectureDetail?.video_lecture?.teacher_image === undefined && lectureDetail?.video_lecture?.teacher_imagenk === null ? 
                                        <img src={noPerson} alt="" width={48} height={48} className='me-2' style={{ borderRadius: '50%' }}/>
                                        :
                                        <img src={`${lectureDetail?.video_lecture?.teacher_image_path}${lectureDetail?.video_lecture?.teacher_image}`} alt="" width={48} height={48} className='me-2' style={{ borderRadius: '50%' }}/>
                                    
                                    }
                                
                                    <div className='d-flex align-items-center'>
                                        <div>
                                            <p className='published-title mb-0'>Published by</p>
                                            <p className='published-person mb-0'>{lectureDetail?.video_lecture?.teacher_name}</p>
                                        </div>
                                        <div className='lecture-published-section mx-3'></div>
                                        <div>
                                            <p className='published-title mb-0'>Published on</p>
                                            <p className='published-person mb-0 balooda-medium'>{dateConverter(lectureDetail?.video_lecture?.lecture_published_date)}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='d-flex align-items-center'>

                                    {
                                        lectureDetail?.video_lecture?.lecture_web_link && 
                                        <a className='lecture-content-section' rel="noopener noreferrer" target="_blank" href={lectureDetail?.video_lecture?.lecture_web_link}>
                                            <div className='content-link-section d-flex align-items-center'>
                                                <img src={linkIcon} className='pe-2' alt=""  />
                                                <p className='mb-0 font-400 text-dark'>Web Link</p>
                                            </div>
                                        </a>
                                    }
                                    {/* <div className='lecture-content-section'>
                                        <div className='content-link-section d-flex align-items-center'>
                                            <img src={linkIcon} className='pe-2' alt=""  />
                                            <p className='mb-0 font-400 text-dark'>Web Link</p>
                                        </div>
                                    </div> */}
                                    {/* <a className='lecture-content-section' target="_blank" href='{}'>
                                        <div className='content-link-section d-flex align-items-center'>
                                            <img src={youtubeVideoIcon} className='pe-2' alt=""  />
                                            <p className='mb-0 font-400 text-dark'>Class Content Link</p>
                                        </div>
                                    </a> */}
                                    {
                                        lectureDetail?.video_lecture?.lecture_file &&
                                        <a className='lecture-content-section' rel="noopener noreferrer" target="_blank" href={`${lectureDetail?.lecture_file_path}${lectureDetail?.video_lecture?.lecture_file}`}>
                                            <div className='content-link-section d-flex align-items-center'>
                                                <img src={pdfIcon} className='pe-2' alt=""  />
                                                <p className='mb-0 font-400 text-dark'>File</p>
                                            </div>
                                        </a>
                                    }
                                </div>

                            </div>
                        </div>
                             
                        </div>
                    </div>
                </div>
        </div>
    );
};

export default LectureView;