import React, { useEffect, useState } from "react";
import {  useLocation } from "react-router-dom";
import "./CourseDetails.css";
/* import CourseDetailsInformation from "./CourseDetailsInformation/CourseDetailsInformation"; */
import CourseShortInformation from "./CourseShortInformation/CourseShortInformation";
import Icon from "../../../../images/people.png";
import videoIconCourseDetails from "../../../../images/course-video.png";
import CourseDescription from "./CourseDetailsInformation/CourseDescription/CourseDescription";
import ClassSchedule from "./CourseDetailsInformation/ClassSchedule/ClassSchedule";
import CourseReview from "./CourseDetailsInformation/CourseReview/CourseReview";
import NoVideo from "../../../../images/default_video_banner.jpg";
import Faq from "./CourseDetailsInformation/Faq/Faq";
import { decode as base64_decode} from "base-64";
import PageTitle from "../PageTitle/PageTitle";
import Loader from "../Loader/Loader";
import { createContext } from "react";
import { englishToBengaliNumberFormatWithZero } from "../../../../utilities/utilities";
import Card from "../../components/Home/Courses/Card/Card";
import ScrollToTop from "../ScrollToTop/ScrollToTop";

export const CourseDetailContext = createContext({});

const CourseDetails = () => {
    const [courseDetail, setCourseDetail] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    /* get id from params */
    const search = useLocation().search;
    const course_id = new URLSearchParams(search).get("course_id");
    const affiliate_id = new URLSearchParams(search).get("aff");
    let decode = base64_decode(`${course_id}`);

    /* let location = useLocation();
    let from = location?.state?.from; */
     /* id decrypt  */
    /* console.log(course_id)
    var bytes = await (CryptoJS.AES.decrypt(course_id, "mojaru_v_2"));
    var originalText =await (bytes.toString(CryptoJS.enc.Utf8)); 
    console.log(originalText); */

    useEffect(() => {
        async function fetchMyAPI() {
            let response = await fetch(
                `${process.env.REACT_APP_BASE_URL}api/course-detail?course_id=${decode}&user_id=${localStorage.getItem('user_id')}`
            );
            response = await response.json();
            setCourseDetail(response.data);
            /* console.log(response.data); */
            setIsLoading(false);
        }
        fetchMyAPI();
    }, [decode]);


    if(isLoading){
        return (<Loader/>);
    }
    


    return (
        <CourseDetailContext.Provider value={courseDetail}>
            <PageTitle title="Course Details"></PageTitle>
            <ScrollToTop></ScrollToTop>
            <>
                    {/* <Header></Header> */}
                    <div className="borderTop" style={{ paddingBottom: "23rem" }}>
                        <div className="container">
                            <div className="row mx-0 gy-3 course-detail-top">
                                <div className="col-lg-9">
                                    {/* <p className="update-date"> সর্বশেষ আপডেট হয়েছে: 21 ডিসেম্বর 2021</p> */}
                                    <p className="course-name"> { courseDetail?.course_detail?.course_name } </p>
                                    <div className="d-flex">

                                    {
                                        courseDetail?.total_students >= 50 && 
                                        <div className="d-flex align-items-center pe-4">
                                            <img src={Icon} alt="" height={18} width={20} />
                                            <p className="font-16 mb-0 pt-1 ps-1 course-student balooda-regular"> ভর্তি হয়েছে : { englishToBengaliNumberFormatWithZero(courseDetail?.total_students) } জন </p>
                                        </div>
                                    }
                                        <div className="d-flex align-items-center">
                                            <img src={videoIconCourseDetails} alt="" height={18}  width={21} />
                                            <p className="font-16 mb-0 pt-1 ps-1 course-student balooda-regular"> বিষয় : { englishToBengaliNumberFormatWithZero(courseDetail?.course_subject_count) } টি </p>
                                        </div>
                                    </div>

                                    {courseDetail?.course_detail?.course_introduction_video_link !== undefined && courseDetail?.course_detail?.course_introduction_video_link !== null ? (
                                        <iframe
                                            className="course-video"
                                            src={`https://www.youtube.com/embed/${courseDetail?.course_detail?.course_introduction_video_link}`}
                                            title="YouTube video player"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        ></iframe>
                                    ) : (
                                        <img src={NoVideo} alt="" className="my-4" style={{ width: "100%" }} />
                                    )}

                                    <ul className="nav nav-tabs profileTab" id="myTab" role="tablist">
                                        <li className="nav-item profileNavItem" role="presentation">
                                            <button
                                                className="nav-link profileNavLink active mb-0 course_details_tap_title"
                                                id="description-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#description"
                                                type="button"
                                                role="tab"
                                                aria-controls="description"
                                                aria-selected="true"
                                            >
                                                বর্ণনা
                                            </button>
                                        </li>
                                        <li className="nav-item profileNavItem" role="presentation">
                                            <button
                                                className="nav-link profileNavLink mb-0"
                                                id="curriculum-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#curriculum"
                                                type="button"
                                                role="tab"
                                                aria-controls="curriculum"
                                                aria-selected="false"
                                            >
                                                পাঠ্যক্রম
                                            </button>
                                        </li>

                                        <li className="nav-item profileNavItem" role="presentation">
                                            <button
                                                className="nav-link profileNavLink mb-0"
                                                id="review-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#my_review"
                                                type="button"
                                                role="tab"
                                                aria-controls="my_review"
                                                aria-selected="false"
                                                
                                            >
                                                রিভিউ
                                            </button>
                                        </li>

                                        <li className="nav-item profileNavItem" role="presentation">
                                            <button
                                                className="nav-link profileNavLink mb-0"
                                                id="faq-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#faq"
                                                type="button"
                                                role="tab"
                                                aria-controls="faq"
                                                aria-selected="false"
                                                style={{ width: "100%" }}
                                            >
                                                <span className="course-faq-btn">সাধারণ</span> জিজ্ঞাসা
                                            </button>
                                        </li>
                                    </ul>

                                    <div className="tab-content profileTabContent" id="myTabContent">
                                        <div className="tab-pane fade show active" id="description" role="tabpanel" aria-labelledby="description-tab">
                                            <CourseDescription description={ courseDetail?.course_detail?.course_description }></CourseDescription>
                                        </div>
                                        <div className="tab-pane fade" id="curriculum" role="tabpanel" aria-labelledby="curriculum-tab">
                                            <div className="faq-sections">
                                                <div className="container" data-aos="fade-up">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="d-flex align-items-start mb-3 public-syllabus-show">
                                                                <div className="nav flex-column nav-pills assignment-subject p-2 bg-white custom-card-shadow mt-4 me-3 public-syllabus-subject-show" id="v-pills-tab" role="tablist" aria-orientation="vertical" style={{ borderRadius:'8px' }}>
                                                                    {
                                                                        courseDetail?.syllabuses?.map((syllabus, index) => 
                                                                            <button key={syllabus?.subject_id} className={`text-start nav-link text-dark public-syllabus font-400 ${index === 0 ? "active" : ""}`} id={`v-pills-public-syllabus-${syllabus?.subject_id}-tab`} data-bs-toggle="pill" data-bs-target={`#v-pills-public-syllabus-${syllabus?.subject_id}`} type="button" role="tab" aria-controls={`#v-pills-public-syllabus-${syllabus?.subject_id}`} aria-selected={index === 0 ? "true" : "false"} >{syllabus?.subject_name}</button>
                                                                        )
                                                                    }
                                                                </div>
                                                                <div className="tab-content w-100 bg-white custom-card-shadow all-assignment-section mt-4" id="v-pills-tabContent">
                                                                
                                                                {
                                                                courseDetail?.syllabuses?.map((syllabus, index) => {
                                                                return(
                                                                        <div key={syllabus?.subject_id}  className={index === 0 ? 'tab-pane fade active show' : 'tab-pane fade' } id={`v-pills-public-syllabus-${syllabus?.subject_id}`} role="tabpanel" aria-labelledby={`v-pills-public-syllabus-${syllabus?.subject_id}-tab`}>
                                                                            <p className='course-total-assignment mb-0'>{syllabus?.syllabus_title}</p>
                                                                            <div className='total-assignment-divider'></div>
                                                                            <div className="accordion accordion-flush mt-4 px-4" id="public-syllabus">
                                                                                {
                                                                                    syllabus?.lessons?.map((lesson, index) => {
                                                                                            return <ClassSchedule key={index} lesson={lesson} LessonSerial={index}/>
                                                                                    } )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                
                                                                )}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="tab-pane fade" id="faq" role="tabpanel" aria-labelledby="faq-tab">
                                            <div className="faq-sections">
                                                <div className="container" data-aos="fade-up">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="accordion accordion-flush mt-4" id="faqlist2">
                                                                {courseDetail?.course_faqs?.map(
                                                                    ( course_faq ) => (
                                                                        <Faq key={course_faq.course_faq_id} course_faq={ course_faq }></Faq>
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tab-pane fade" id="my_review"role="tabpanel" ria-labelledby="review-tab">
                                            <CourseReview course_id={decode} isCoursePurchase={courseDetail?.course_detail?.already_purchased}></CourseReview>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 ">
                                    <CourseShortInformation
                                        id={decode}
                                        isCoursePurchase={courseDetail?.course_detail?.already_purchased}
                                        courseDetail={ courseDetail?.course_detail }
                                        course_features={ courseDetail?.course_features }
                                        course_teachers={ courseDetail?.course_teachers }
                                        teacher_profile_path={ courseDetail?.teacher_profile_picture_path }
                                        affiliate_id = {affiliate_id}
                                    ></CourseShortInformation>
                                </div>
                            </div>

                            
                            <div>
                            {
                                courseDetail?.popular_courses?.length > 0 &&
                                <p className="popular-course-title px-2 ">মজারুর জনপ্রিয় কোর্স</p>
                            }
                                <div className="row gy-4 px-2">
                                    {
                                        courseDetail?.popular_courses?.slice(0,3).map(course => <Card key={course?.id} course={course} path_link={courseDetail?.course_image_path}/>)
                                    }
                                </div>
                            </div>
                            
                        </div>
                    </div>
            </>
        </CourseDetailContext.Provider>
    );
};

export default CourseDetails;
