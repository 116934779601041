import React, { useEffect, useState } from "react";
import logo from "../../../../images/logo_white.png";
import google_play from "../../../../images/btn_google_play.svg";
import { Link } from "react-router-dom";
import "./Footer.css";
import ReactWhatsapp from "react-whatsapp";
import { englishToBengaliNumberFormat } from "../../../../utilities/utilities";

const Footer = ({location}) => {
    const [settings, setSettings] = useState([]);

    //setting
    useEffect(() => {
        async function fetchWebsiteSetting() {
            let response = await fetch(
                `${process.env.REACT_APP_BASE_URL}api/settings`
            );
                response = await response.json();
                setSettings(response.data.settings);
        
        }
        fetchWebsiteSetting();
    }, []);


    return (
        <footer
            style={
                location === "/login" ||
                location === "/registration" ||
                location === "/otp" || location === "/schedule/class"
                    ? { display: "none" }
                    : { display: "block" }
            }
        >
            <div
                className="container-fluid pb-3"
                style={{ backgroundColor: "#292929" }}
            >
                <div className="container">
                    <div className="row pt-5 gy-5">
                        <div className="col col-lg-3 col-md-3 col-sm-6 col-12">
                            <div className="footer-img">
                                <img src={logo} alt="" />
                            </div>
                            <div>
                                <p className="text_color_gray pt-4 font-16 footer-text">
                                    মজারু একটি ই-লার্নিং প্লাটফর্ম। মজারু মানে
                                    মজার শিক্ষাগুরু। মজারু হাজির হয়েছে পড়াশোনার
                                    এক আনন্দময় জগৎ নিয়ে। মজারু কাজ করছে শিশুদের
                                    ব্রেইন আর স্কিল ডেভেলপমেন্ট নিয়ে। ফোর্থ
                                    ইন্ডাস্ট্রিয়াল রেভ্যুলেশনের চ্যালেঞ্জ
                                    মোকাবেলায় একঝাঁক দক্ষ জনগোষ্ঠী তৈরি করাই
                                    মজারুর লক্ষ্য। দরকার শুধু ইন্টারনেট সংযোগ,
                                    মোবাইল বা ল্যাপটপ।
                                </p>
                            </div>
                            <div className="footer-img">
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-decoration-none"
                                    href={settings.website_facebook_link}
                                    title="Go to facebook page"
                                    aria-label="Go to facebook page"
                                >
                                    {" "}
                                    <i className="fa-brands fa-facebook text_color_gray fa-facebook"></i>
                                </a>
                                {/* <a
                                    target="_blank"
                                    className="text-decoration-none"
                                    href={settings.website_twitter_link}
                                >
                                    {" "}
                                    <i className="fa-brands fa-twitter text_color_gray fa-twitter ps-3"></i>
                                </a> */}
                                <a 
                                    target="_blank" 
                                    className="text-decoration-none" 
                                    rel="noreferrer" 
                                    href={settings.website_instagram_link}
                                    title="Go to instagram page"
                                    aria-label="Go to instagram page"
                                >
                                    <i className="fa-brands fa-instagram text_color_gray fa-instagram ps-3"></i>
                                </a>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-decoration-none"
                                    href={settings.website_youtube_page_link}
                                    title="Go to youtube page"
                                    aria-label="Go to youtube page"
                                >
                                    <i className="fa-brands fa-youtube text_color_gray fa-youtube ps-3"></i>
                                </a>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-decoration-none"
                                    href={settings.website_linkdein_link}
                                    title="Go to linkdein page"
                                    aria-label="Go to linkdein page"
                                >
                                    <i className="fa-brands fa-linkedin text_color_gray fa-linkedin ps-3"></i>
                                </a>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-decoration-none"
                                    href={settings?.website_tiktok_link}
                                    title="Go to tiktok page"
                                    aria-label="Go to tiktok page"
                                >
                                    <i className="fa-brands fa-tiktok ps-3 text_color_gray ps-3"></i>
                                </a>

                                <ReactWhatsapp
                                    number={`${settings?.whatsapp_number}`}
                                    style={{
                                        border: "none",
                                        backgroundColor: "transparent",
                                    }}
                                    aria-label="whats-message"
                                    aria-labelledby="whats-message-label"
                                >
                                    <i className="fa-brands fa-whatsapp ps-lg-3 ps-2 text-white icon-font-size"></i>
                                </ReactWhatsapp>
                                {/* <a
                                    target="_blank"
                                    className="text-decoration-none"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="bottom"
                                    title={`${settings?.whatsapp_number}`}
                                    style={{ cursor: "pointer" }}
                                >
                                    <i className="fa-brands fa-whatsapp ps-3 text_color_gray ps-3"></i>
                                </a> */}
                            </div>
                        </div>
                        <div className=" col-lg-3 col-md-3 col-sm-6 col-6">
                            <div className="d-flex justify-content-center">
                                <div>
                                    <h4 className="text-white fw-bold mt-3">
                                        এক্সপ্লোর
                                    </h4>
                                    <div className="pt-4">
                                        <Link
                                            to="/about"
                                            className="text_color_gray pt-4 font-16 text-decoration-none footerPage"
                                        >
                                            আমাদের সম্পর্কে
                                        </Link>
                                    </div>
                                    <div className="pt-3">
                                        <Link
                                            to="/terms_and_condition"
                                            className="text_color_gray font-16 text-decoration-none footerPage"
                                        >
                                            সেবা পাবার শর্ত
                                        </Link>
                                    </div>
                                    <div className="pt-3">
                                        <Link
                                            to="/faq"
                                            className="text_color_gray font-16 text-decoration-none footerPage"
                                        >
                                            সাধারণ জিজ্ঞাসা
                                        </Link>
                                    </div>
                                    <div className="pt-3">
                                        <Link
                                            to="/e-book"
                                            className="text_color_gray font-16 text-decoration-none footerPage"
                                        >
                                            বই
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                            <div className="d-flex justify-content-center">
                                <div>
                                    <h4 className="text-white fw-bold mt-3">
                                        সহায়তা
                                    </h4>
                                    <div className="pt-4">
                                        <Link
                                            to="/refund"
                                            className="text_color_gray pt-4 font-16 text-decoration-none footerPage"
                                        >
                                            রিফান্ড এবং রিটার্ন নীতি
                                        </Link>
                                    </div>
                                    <div className="pt-3">
                                        <Link
                                            to="/privacy_policy"
                                            className="text_color_gray font-16 text-decoration-none footerPage"
                                        >
                                            গোপনীয়তা নীতি
                                        </Link>
                                    </div>
                                    <div className="pt-3">
                                        <Link
                                            to="/blog"
                                            className="text_color_gray font-16 text-decoration-none footerPage"
                                        >
                                            ব্লগ
                                        </Link>
                                    </div>
                                    <div className="pt-3">
                                        <Link
                                            to="/contact"
                                            className="text_color_gray font-16 text-decoration-none footerPage"
                                        >
                                            যোগাযোগ
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-3 col-sm-6 col-12">
                            <div className="d-flex justify-content-center">
                                <div>
                                    <h4 className="text-white fw-bold mt-3 footer-img">ডাউনলোড</h4>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        className="text-decoration-none"
                                        href={settings.play_store_link}
                                        title="Go to google play"
                                        aria-label="Go to google play"
                                    >
                                        <img src={google_play} alt="example" style={{ height: '50px' }} />
                                    </a>
                                    {/* <div className="d-flex justify-content-center pt-3">
                                        <img
                                            src={apple_store}
                                            alt=""
                                            className="rounded-3 bg-white"
                                        />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <p className="text-center bg-dark py-3 mb-0" style={{ color: "#9B9B9B" }}>
                    কপিরাইট {englishToBengaliNumberFormat(new Date().getFullYear())} © মজারু এডুকেশন টেকনোলজিস লিমিটেড.
                </p>
            </div>
        </footer>
    );
};

export default Footer;
