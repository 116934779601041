import React from 'react';
import './CourseDescription.css';
import parse from 'html-react-parser';
const CourseDescription = ({description}) => {
    return (
        <div>
            <div className='description-section'>
                {
                    description && parse(description)
                }
                {/* { parse(description) } */}
                {/* <h2>সার্টিফিকেট</h2>

                <img src={} alt="" className='img-fluid'/> */}
            </div>
        </div>
    );
};

export default CourseDescription;