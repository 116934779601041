import React, { useEffect, useState } from 'react';
import BookCard from './BookCard/BookCard';

const MyBooks = () => {
    const [purchasedEbooks, setPurchasedEbooks] = useState([]);


    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_URL}api/book-store?user_id=${localStorage.getItem("user_id")}`,{
                    method:'GET',
                    headers:{
                        'content-type' : 'application/json',
                        'accept' : 'application/json',
                        'Authorization' : `Bearer ${localStorage.getItem("auth_token")}`,
                        'SecretKey' : `${process.env.REACT_APP_API_URL_SECRET_KEY}`,
                    },
                })
            .then((res) => res.json())
            .then((data) => setPurchasedEbooks(data?.data));
    }, []);
    return (
        <div className="main-content">
            <div className="page-content p-0">
                <div className="container-fluid">
                    <div className="my-course-section">
                    <p className="my-course-title mb-1">ই-বুক</p>
                        <p style={{ fontWeight: "400" }}>
                        আপনার কেনা ই-বুকের লিস্ট
                        </p>
                        {
                            purchasedEbooks?.purchased_ebooks?.length === 0 && <p className="d-flex justify-content-center mb-0 w-100" style={{ fontSize: "18px", fontWeight: "600" }}>এখনও কোনো বই কেনা হয়নি।</p>
                        }
                        <div className="row g-3">
                            {
                                purchasedEbooks?.purchased_ebooks?.map(purchasedEbook => 
                                <BookCard
                                key={purchasedEbook?.e_book_id} 
                                purchasedEbook={purchasedEbook}
                                ebook_image_path={purchasedEbooks?.ebook_image_path}
                                author_image_path={purchasedEbooks?.author_image_path}
                                />)
                            }
                        </div>

                        


                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyBooks;