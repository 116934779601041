import React from "react";
import { englishToBengaliNumberFormat, topicType } from "../../../../../../../utilities/utilities";
/* import syllabusLink from '../../../.../../../../../../../../../public/images/box-arrow-up-right.svg';
import syllabusLock from '../../../.../../../../../../../../../public/images/syllabus-lock-fill.svg';
import lessonVideoLectureIcon from '../../../.../../../../../../../../../public/images/lesson video lecture icon.svg';
import lessonSmartNoteIcon from '../../../.../../../../../../../../../public/images/lesson smart note icon.svg';
import lessonQuizIcon from '../../../.../../../../../../../../../public/images/lesson quiz icon.svg'; */
import './TotalClass.css';

const TotalClass = ({topic, topicSerial, LessonSerial}) => {

    return (
        <tr>
            <td className="topic-serial balooda-regular">
                {englishToBengaliNumberFormat(LessonSerial + 1)}.
                {englishToBengaliNumberFormat(topicSerial + 1)}
            </td>
            <td className="topic-category" style={{ width: "12rem" }}>
                <div className="d-flex align-items-center">
                    {topicType(topic?.assesment_type)}

                    {/* <img src={lessonVideoLectureIcon} className='video-lecture-icon' alt="" /> 
                    <p className='mb-0 ms-2'>ভিডিও লেকচার</p> */}
                </div>
            </td>
            <td className="topic-serial">:</td>
            <td className="topic-details">{topic?.topic_name}</td>
        </tr>
    );
};

export default TotalClass;
