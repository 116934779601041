import React from 'react';
import bkashIcon from '../../../../images/bKash-logo.svg';
import nagadPaymentIcon from '../../../../images/nagad_payment.png';
import creditIcon from '../../../../images/credit-card.svg';
/* import walletIcon from '../../../../images/wallet.svg'; */

const PaymentMethod = ({data}) => {
    /* console.log(data?.affiliate_code); */
    return (
        <div>
            <p className='mb-3' style={{ fontSize:'24px', fontWeight:'600' }}>পেমেন্ট মেথড সিলেক্ট করো </p>

            {/* Bkash Payment */}
            <form action={`${process.env.REACT_APP_BASE_URL}student/initialize-bkash-payment-via-web`} method='post'>

                    <input type="hidden" value={data?.transaction_amount} name="transaction_amount"/>
                    <input type="hidden" value={data?.user_id} name="user_id"/>
                    <input type="hidden" value={data?.user_name} name="user_name"/>
                    <input type="hidden" value={data?.user_mobile_number} name="user_mobile_number"/>
                    <input type="hidden" value={data?.user_address} name="user_address"/>
                    <input type="hidden" value={data?.course_id} name="course_id"/>
                    <input type="hidden" value={data?.course_name} name="course_name"/>
                    <input type="hidden" value={data?.batch_id} name="batch_id"/>
                    <input type="hidden" value={data?.batch_name} name="batch_name"/>
                    <input type="hidden" value={data?.batch_start_date} name="batch_start_date"/>
                    <input type="hidden" value={data?.batch_total_duration} name="batch_total_duration"/>
                    <input type="hidden" value={data?.course_total_price} name="course_total_price"/>
                    <input type="hidden" value={data?.admission_period} name="admission_period"/>
                    <input type="hidden" value={data?.course_original_price} name="course_original_price"/>
                    <input type="hidden" value={data?.course_total_price} name="course_total_price"/>
                    <input type="hidden" value={data?.coupon_id} name="coupon_id"/>
                    <input type="hidden" value={data?.coupon_code} name="coupon_code"/>
                    <input type="hidden" value={data?.affiliate_code} name="affiliate_code"/>
                    <input type="hidden" name="book_id"/>
                    <input type="hidden" name="book_title"/>
                    <input type="hidden" value="buy_course" name="transaction_type"/>
                    <button type='submit' className="btn-new-admission mb-2">
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <img src={bkashIcon} height={30} width={35} alt="" style={{ color:'red' }}/>
                                    <span className="ps-3">
                                    বিকাশ
                                    </span>
                                </div>
                            <div><i className="fa-solid fa-angle-right"></i></div>
                        </div>
                    </button>
            </form>

            {/* Nagad payment */}
             {/* <form action={`${process.env.REACT_APP_BASE_URL}student/nagad-payment`} method='get'>
                <input type="hidden" value={data?.transaction_amount} name="transaction_amount"/>
                <input type="hidden" value={data?.user_id} name="user_id"/>
                <input type="hidden" value={data?.user_name} name="user_name"/>
                <input type="hidden" value={data?.user_mobile_number} name="user_mobile_number"/>
                <input type="hidden" value={data?.user_address} name="user_address"/>
                <input type="hidden" value={data?.course_id} name="course_id"/>
                <input type="hidden" value={data?.course_name} name="course_name"/>
                <input type="hidden" value={data?.batch_id} name="batch_id"/>
                <input type="hidden" value={data?.batch_name} name="batch_name"/>
                <input type="hidden" value={data?.batch_start_date} name="batch_start_date"/>
                <input type="hidden" value={data?.batch_total_duration} name="batch_total_duration"/>
                <input type="hidden" value={data?.course_total_price} name="course_total_price"/>
                <input type="hidden" value={data?.admission_period} name="admission_period"/>
                <input type="hidden" value={data?.course_original_price} name="course_original_price"/>
                <input type="hidden" value={data?.course_total_price} name="course_total_price"/>
                <input type="hidden" value={data?.coupon_id} name="coupon_id"/>
                <input type="hidden" value={data?.coupon_code} name="coupon_code"/>
                <input type="hidden" value={data?.affiliate_code} name="affiliate_code"/>
                <input type="hidden" name="book_ids"/>
                <input type="hidden" name="book_title"/>
                <input type="hidden" value="buy_course" name="transaction_type"/>
                <button type='submit' className="btn-new-admission mb-2">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <img src={nagadPaymentIcon} height={35} width={35} alt="" style={{ color:'red' }}/>
                                <span className="ps-3">
                                নগদ
                                </span>
                            </div>
                        <div><i className="fa-solid fa-angle-right"></i></div>
                    </div>
                </button>
            </form> */}
            

            {/* SSL commerce */}
            <form action={`${process.env.REACT_APP_BASE_URL}api/pay-via-ssl`} method='post'>

                    <input type="hidden" value={data?.transaction_amount} name="transaction_amount"/>
                    <input type="hidden" value={data?.user_id} name="user_id"/>
                    <input type="hidden" value={data?.user_name} name="user_name"/>
                    <input type="hidden" value={data?.user_mobile_number} name="user_mobile_number"/>
                    <input type="hidden" value={data?.user_address} name="user_address"/>
                    <input type="hidden" value={data?.course_id} name="course_id"/>
                    <input type="hidden" value={data?.course_name} name="course_name"/>
                    <input type="hidden" value={data?.batch_id} name="batch_id"/>
                    <input type="hidden" value={data?.batch_name} name="batch_name"/>
                    <input type="hidden" value={data?.batch_start_date} name="batch_start_date"/>
                    <input type="hidden" value={data?.batch_total_duration} name="batch_total_duration"/>
                    <input type="hidden" value={data?.course_total_price} name="course_total_price"/>
                    <input type="hidden" value={data?.admission_period} name="admission_period"/>
                    <input type="hidden" value={data?.course_original_price} name="course_original_price"/>
                    <input type="hidden" value={data?.course_total_price} name="course_total_price"/>
                    <input type="hidden" value={data?.coupon_id} name="coupon_id"/>
                    <input type="hidden" value={data?.coupon_code} name="coupon_code"/>
                    <input type="hidden" value={data?.affiliate_code} name="affiliate_code"/>
                    <input type="hidden" name="book_id"/>
                    <input type="hidden" name="book_title"/>
                    <input type="hidden" value="buy_course" name="transaction_type"/>
                    <button type='submit' className="btn-new-admission mb-2">
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                            <img src={creditIcon} alt="" style={{ color:'red' }}/>
                                <span className="ps-3">
                                ক্রেডিট ও ডেবিট কার্ড 
                                </span>
                            </div>

                            <div><i className="fa-solid fa-angle-right"></i></div>
                        </div>
                    </button>
            </form>

        </div>
    );
};

export default PaymentMethod;