import React from "react";
import './LeaderBoard.css';
import { useState, useEffect, useContext } from "react";
import { LMSContext } from "../LMS";
import LeaderBoardStudent from "./LeaderBoardStudent/LeaderBoardStudent";

const LeaderBoard = () => {
    const {batch_id, course_id} = useContext(LMSContext);
    const [leaderBoardStudents, setLeaderBoardStudents] = useState();
    useEffect(()=>{
        fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}leader-board?course_id=${course_id}&batch_id=${batch_id}`)
            .then((res) => res.json())
            .then((data) => setLeaderBoardStudents(data.data));
    },[course_id, batch_id])


    return (
        <div className="bg-white custom-card-shadow" style={{ borderRadius: "16px" }}>
            <p className="course-total-assignment mb-0">Leader board</p>
            <div className="total-assignment-divider"></div>
            <div className="p-4">
                <div className="leader-board-section">
                    <table className="table table-borderless mb-0 my-4">
                        <thead className="px-4">
                            <tr>                               
                                <td>
                                    
                                </td>                               
                                <td className="leader-board-title text-center">
                                    Rank
                                </td>                               
                                <td className="leader-board-title">
                                    Name
                                </td>                               
                                <td className="leader-board-title text-center">
                                    Marks
                                </td>                               
                            </tr>
                        </thead>
                        <tbody>
                        {
                            leaderBoardStudents?.leader_board?.sort((a,b) => b.marks.localeCompare(a.marks)).map((leaderBoardStudent, index) => 
                            <LeaderBoardStudent 
                            key={index} 
                            leaderBoardStudent={leaderBoardStudent} 
                            count= {leaderBoardStudents?.leader_board?.length} 
                            index={index}
                            studentImagePath = {leaderBoardStudents?.student_profile_picture_path} /> )
                        }
                            


                            
                            
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    );
};

export default LeaderBoard;
