import React from 'react';
import { useEffect } from 'react';
/* import { useState } from 'react'; */
import Swiper from 'swiper';
import FeaturedBlog from './FeaturedBlog/FeaturedBlog';

const FeaturedBlogs = ({blogs,blog_image_path}) => {
        /* const [mySwiper, setMySwiper] = useState(null); */
        useEffect(() => {
        new Swiper(".swiper", {
            loop: true,
            pagination: {
                el: ".swiper-pagination",
                type: "fraction",
              },
              autoplay: {
                delay: 3000,
              },
        });
        /* setMySwiper(mySwiper); */
        
    }, []);
    return (
        <section className="position-sticky" style={{ top:'16rem' }}>
            <div className="swiper mySwiper">
                <div className="swiper-wrapper">
                    {
                        blogs?.featured_blogs?.map(featured_blog => <FeaturedBlog key={featured_blog.id} featured_blog={featured_blog} blog_image_path={blog_image_path}></FeaturedBlog>)
                    }
                </div>
                <div className="ps-5 pb-3 swiper-pagination text-white text-start"></div>
            </div>
        </section>
    );
};

export default FeaturedBlogs;